import { Action, Subject } from "@readcloud/casl";
import { AuthorizedRoute } from "@readcloud/casl-react";
import { FC } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Course } from "./Course";
import { Enrol } from "./Enrol";
import { Home } from "./Home";
import { Join } from "./Join";
import { NotAuthorized } from "./NotAuthorized";
import { NotFound } from "./NotFound";

export const Routes: FC = () => (
  <Switch>
    <AuthorizedRoute
      exact
      path="/"
      permissions={[
        {
          [Subject.Teacher]: Action.Write,
        },
        {
          [Subject.Student]: Action.Write,
        },
      ]}
      redirectTo="/not-authorized"
    >
      <Home />
    </AuthorizedRoute>
    <AuthorizedRoute
      exact
      path="/course/:id"
      permissions={[
        {
          [Subject.Teacher]: Action.Write,
        },
      ]}
      redirectTo="/not-authorized"
    >
      <Course />
    </AuthorizedRoute>
    <AuthorizedRoute
      exact
      path="/enrol/:id"
      permissions={[
        {
          [Subject.Student]: Action.Write,
        },
      ]}
      redirectTo="/not-authorized"
    >
      <Enrol />
    </AuthorizedRoute>
    <AuthorizedRoute
      exact
      path="/join/:key"
      permissions={[
        {
          [Subject.Student]: Action.Write,
        },
      ]}
      redirectTo="/not-authorized"
    >
      <Join />
    </AuthorizedRoute>
    <Route exact path="/not-authorized">
      <NotAuthorized />
    </Route>

    <Route>
      <Redirect to="/" />
    </Route>
  </Switch>
);
