import { Role } from "@readcloud/casl";
import { FC, useContext } from "react";
import { Courses, Enrolments } from "./components";
import { TokenContext } from "@readcloud/casl-react/dist/src/context/token";

export const Home: FC = () => {
  const token = useContext(TokenContext);
  return (
    <div className="container">
      {token?.role === Role.Teacher && (
        <>
          <h1>Your Registered Programs</h1>
          <p>
            Welcome to the <strong>Enrolment Portal</strong>. Listed on this
            page are all of the programs your school has registered across the{" "}
            <strong>ReadCloudVET</strong> group of RTOs. <br /> From here, you
            can click through to the enrolments area for your corresponding
            program, where you’ll be able to invite students and check the
            status of your enrolments.
          </p>
          <Courses />
        </>
      )}
      {token?.role === Role.Student && (
        <>
          <h1>Enrolments</h1>
          <Enrolments />
        </>
      )}
    </div>
  );
};
