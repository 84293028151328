import { Button } from '@readcloud/component-library';
import { useBoolean, useRequest } from 'ahooks';
import { FC } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import 'regenerator-runtime/runtime';
import { ConfirmModal } from '../../../../../../components';
import { ResendInviteProps } from './types';

export const ResendInvite: FC<ResendInviteProps> = ({
  onResend,
  studentName,
}) => {
  const { loading, run } = useRequest(onResend, {
    manual: true,
  });
  const [isModalOpen, { toggle: toggleModal, setTrue, setFalse }] =
    useBoolean(false);

  const onConfirmResend = async () => {
    setFalse();
    await run();
  };

  return (
    <>
      <Button
        id="sendEnrolment"
        className="mr-2"
        size="sm"
        onClick={setTrue}
        disabled={loading}
        title="Send enrolment invite"
      >
        {loading ? (
          <div className="d-flex align-items-center">
            <span className="spinner-border spinner-border-sm icon-button-spinner"></span>
          </div>
        ) : (
          <i className="fe fe-send" />
        )}
      </Button>
      <UncontrolledTooltip placement="bottom" target="sendEnrolment">
        Send Invitation
      </UncontrolledTooltip>
      <ConfirmModal
        isOpen={isModalOpen}
        onCancel={setFalse}
        onConfirm={onConfirmResend}
        onToggle={toggleModal}
        title="Confirm Enrolment Invitation"
      >
        <p>
          Based on my understanding of the requirements for this qualification,
          and my understanding of the learning needs of{' '}
          <strong>{studentName}</strong>, by sending this invitation I declare
          they are eligible to enrol into this program and believe that they
          have a reasonable chance of success.
        </p>
        <p>
          Are you sure you want to send this enrolment invitation to{' '}
          <strong>{studentName}</strong>?
        </p>
      </ConfirmModal>
    </>
  );
};
