import { Button } from '@readcloud/component-library';
import { useBoolean } from 'ahooks';
import { FC, useState } from 'react';
import 'regenerator-runtime/runtime';
import { LLNData } from '../../../../../../api';
import { LLNPointsModal } from './components';
import { EditLLNProps } from './types';

export const EditLLN: FC<EditLLNProps> = ({ llnData, onSubmit }) => {
  const [loading, { toggle }] = useBoolean(false);
  const [error, setError] = useState<Error>(null);
  const [isModalOpen, { toggle: toggleModal, setTrue }] = useBoolean(false);

  const onOpen = () => {
    setError(null);
    setTrue();
  };

  const onModalSubmit = async (values: LLNData) => {
    try {
      toggle();
      await onSubmit(values);
    } catch (err) {
      setError(err);
      toggle();
    }
  };

  return (
    <>
      <Button className="mr-2" title="Edit LLN" size="sm" onClick={onOpen}>
        <i className="fe fe-edit" />
      </Button>
      <LLNPointsModal
        values={llnData}
        isOpen={isModalOpen}
        onToggle={toggleModal}
        onSubmit={onModalSubmit}
        error={error}
        loading={loading}
      />
    </>
  );
};
