import { FC } from 'react';
import { CentrePage } from '../CentrePage';
import { ErrorPageProps } from './types';

export const ErrorPage: FC<ErrorPageProps> = ({
  children,
  icon = 'fe-alert-circle',
}) => (
  <CentrePage>
    <div className="d-flex flex-column justify-content-center align-items-center">
      {icon && <i className={`fe ${icon}`} />}
      <div className="mt-2">{children}</div>
    </div>
  </CentrePage>
);
