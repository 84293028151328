import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { getKeys } from '../utils';

export function useFocusError<T>() {
  const { isValidating, isSubmitting, errors } = useFormikContext<T>();

  useEffect(() => {
    if (isSubmitting && !isValidating && errors) {
      const keys = getKeys(errors);

      if (keys.length > 0) {
        const selector = `[name="${keys[0]}"]`;
        const errorElement = document.querySelector<HTMLElement>(selector);

        if (errorElement) {
          errorElement.focus();
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);
}
