import countryListJson from './countries.json';
import languageListJson from './languages.json';

export const englishLevelOptions = [
  'Very well',
  'Well',
  'Not well',
  'Not at all',
];

export const indigenousOptions = [
  'Aboriginal',
  'Torres Strait Islander',
  'Both',
  'Neither',
];

export const highestCompletedSchoolLevelOptions = [
  'Year 12 or equivalent',
  'Year 11 or equivalent',
  'Year 10 or equivalent',
  'Year 9 or equivalent',
  'Year 8 or below',
  'Never attended school',
];

const currentYear = new Date().getFullYear();

export const completedYearOptions = Array.from(
  Array(100),
  (_, i) => `${currentYear - i}`
);

export const countryOptions = countryListJson.map((country) => country.name);

export const languageOptions = Object.values(languageListJson).map(
  (lang) => lang.name
);

export const employmentStatusOptions = [
  'Full time employee',
  'Part time employee',
  'Self employed - not employing others',
  'Self employed - employing others',
  'Employed - unpaid worker in a family business',
  'Unemployed - seeking full time work',
  'Unemployed - seeking part time work',
  'Not employed - not seeking employment',
];

export const previousQualificationAchievedOptions = [
  'Bachelor Degree or higher degree',
  'Advanced Diploma or associate degree',
  'Diploma (or associate diploma)',
  'Certificate IV (or advanced certificate/technician)',
  'Certificate III (or trade certificate)',
  'Certificate II',
  'Certificate I ',
  'Other education (including certificates or overseas qualifications not listed above)',
];

export const industryOfEmploymentOptions = [
  'None',
  'Agriculture, Forestry and Fishing',
  'Mining',
  'Manufacturing',
  'Electricity, Gas, Water and Waste Services',
  'Construction',
  'Wholesale Trade',
  'Retail Trade',
  'Accommodation and Food Services',
  'Transport, Postal and Warehousing',
  'Information Media and telecommunications',
  'Financial and Insurance Services',
  'Rental, Hiring and real Estate Services',
  'Professional, Scientific and Technical Services',
  'Administrative and Support Services',
  'Public Administration and Safety',
  'Education and Training',
  'Health Care and Social Assistance',
  'Arts and recreation Services',
  'Other Services',
];

export const occupationOptions = [
  'None',
  'Manager',
  'Professionals',
  'Technicians and Trades Workers',
  'Community and Personal Service Workers',
  'Clerical and Administrative Workers',
  'Sales Workers',
  'Machinery Operators and Drivers',
  'Labourers',
  'Other',
];

export const undertakingReasonOptions = [
  'To get a job',
  'To develop my existing business',
  'To start my own business',
  'To try for a different career',
  'To get a better job or promotion',
  'It was a requirement of my job',
  'I wanted extra skills for my job',
  'To get into another course of study',
  'For personal interest or self-development',
  'To get skills for community / voluntary work',
  'Other reasons',
];

export const disabilityClaimOptions = [
  'Hearing/Deaf',
  'Physical',
  'Intellectual',
  'Learning',
  'Mental Illness',
  'Acquired Brain Impairment',
  'Vision',
  'Medical Condition',
  'Other',
  'Unspecified',
];
