import { EnrolmentFormData } from '../../api';

export const initialValues: EnrolmentFormData = {
  schemaVersion: '2',
  personal: {
    title: '',
    firstName: '',
    otherNames: '',
    lastName: '',
    dob: '',
    gender: '',
    differentHomeSchool: false,
    homeSchoolName: '',
  },
  contactDetails: {
    email: '',
    method: '',
    emergencyContact: {
      mobile: '',
      name: '',
      phone: '',
      relationship: '',
    },
    homePhone: '',
    mobile: '',
    workPhone: '',
  },
  address: {
    postal: {
      buildingName: '',
      poBox: '',
      postcode: '',
      state: '',
      streetNameAndType: '',
      streetNumber: '',
      suburb: '',
      unitDetails: '',
      isOutsideAustralia: false,
    },
    residential: {
      buildingName: '',
      postcode: '',
      state: '',
      streetNameAndType: '',
      streetNumber: '',
      suburb: '',
      unitDetails: '',
      isOutsideAustralia: false,
    },
    isResidentialSameAsPostal: true,
  },
  employment: {
    isEmployed: false,
    buildingName: '',
    postcode: '',
    state: '',
    streetNameAndType: '',
    streetNumber: '',
    suburb: '',
    unitDetails: '',
    businessName: '',
    contactName: '',
    payrollId: '',
    phone: '',
    abn: '',
    email: '',
  },
  usi: '',
  acknowledgedPolicy: false,
  acknowledgedConsent: false,
  acknowledgedCorrect: false,
  governmentDetails: {
    countryOfBirth: '',
    townOfBirth: '',
    completedYear: '',
    currentlyEnrolled: false,
    englishLevel: '',
    hasCompletedQualifications: false,
    highestCompletedSchoolLevel: '',
    indigenous: '',
    otherLanguages: '',
    disabilityClaims: [],
    employmentIndustry: '',
    employmentStatus: '',
    hasDisability: false,
    occupation: '',
    undertakingReason: '',
    qualifications: [],
    notes: '',
  },
};
