import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@readcloud/component-library';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { FC } from 'react';
import { AddStudentModalProps } from './types';
import { validationSchema } from './validation';

export const AddStudentModal: FC<AddStudentModalProps> = ({
  isOpen,
  onToggle,
  onSubmit,
  error,
  loading,
}) => {
  const onCancel = () => {
    onToggle(false);
  };

  const toggle = () => {
    if (!loading) {
      onToggle();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <ModalTitle>Add Student</ModalTitle>
      </ModalHeader>
      <p className="pt-5 px-4">
        In this window, you’ll be able to enter your student’s details in the
        field below and click <strong>‘add’</strong> to add them into the
        enrolment queue.{' '}
        <strong>Please check the information entered below </strong>to ensure
        there are no errors in the name or email address, as these details will
        be used to create their student account in our system, and to send their
        ‘enrolment invite’ email.
      </p>
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        validateOnBlur={false}
      >
        {({ values, touched, errors, handleBlur, handleChange }) => (
          <Form>
            <ModalBody>
              <FormGroup>
                <Label required>First Name</Label>
                <Input
                  invalid={touched.firstName && Boolean(errors.firstName)}
                  placeholder="Enter first name"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.firstName && Boolean(errors.firstName) && (
                  <div className="text-danger">{errors.firstName}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label required>Last Name</Label>
                <Input
                  invalid={touched.lastName && Boolean(errors.lastName)}
                  placeholder="Enter last name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.lastName && Boolean(errors.lastName) && (
                  <div className="text-danger">{errors.lastName}</div>
                )}
              </FormGroup>
              <div
                className={classNames({
                  'form-group': Boolean(error),
                })}
              >
                <Label required>Email</Label>
                <Input
                  invalid={touched.email && Boolean(errors.email)}
                  placeholder="Enter email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.email && Boolean(errors.email) && (
                  <div className="text-danger">{errors.email}</div>
                )}
              </div>
              {error && (
                <div className="text-danger text-center">{error.message}</div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                variant="secondary"
                onClick={onCancel}
                disabled={loading}
                className="mr-2"
              >
                Cancel
              </Button>
              <Button submit disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                Add
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
