export interface RtoConfig {
  logo: string;
  number: string;
  name: string;
  enrolInvitationEmailId: string;
  enrolSuccessEmailId: string;
}

export interface Course {
  id: string;
  name: string;
  description: string;
  memberCount?: number;
  enrolments?: Enrolment[];
  extConfig?: {
    program?: {
      id: string;
      enrolmentOpenDate?: string;
      enrolmentCloseDate?: string;
    };
  };
  rto?: RtoConfig;
}

export interface Enrolment {
  _id: string;
  cloud: string;
  schoolName: string;
  courseName?: string;
  courseDescription?: string;
  email: string;
  firstName: string;
  lastName: string;
  completed?: boolean;
  llnData: LLNData;
  invitation: Invitation;
  formData: EnrolmentFormData;
  user: string;
  rto?: RtoConfig;
}

export interface EnrolmentFormData {
  schemaVersion: string;
  personal: PersonalDetailsProps;
  address: AddressDetailsProps;
  contactDetails: ContactDetailsProps;
  employment: EmploymentDetailsProps;
  usi?: string;
  lui?: string;
  acknowledgedPolicy?: boolean;
  acknowledgedCorrect?: boolean;
  acknowledgedConsent?: boolean;
  notes?: string;
  governmentDetails: GovernmentDetailsProps;
}

export interface PersonalDetailsProps {
  title?: string;
  firstName: string;
  otherNames?: string;
  lastName: string;
  dob: string;
  gender?: string;
  differentHomeSchool?: boolean;
  homeSchoolName?: string;
}

export interface AddressDetailsProps {
  residential: Address;
  postal: PostalAddress;
  isResidentialSameAsPostal?: boolean;
}

export interface ContactDetailsProps {
  homePhone?: string;
  workPhone?: string;
  mobile?: string;
  email: string;
  method?: string;
  emergencyContact?: EmergencyContact;
}

export interface EmergencyContact {
  name?: string;
  relationship?: string;
  phone?: string;
  mobile?: string;
}

export interface Address {
  unitDetails?: string;
  buildingName?: string;
  streetNumber: string;
  streetNameAndType: string;
  suburb: string;
  isOutsideAustralia?: boolean;
  state?: string;
  postcode: string;
}

export interface PostalAddress extends Address {
  poBox?: string;
}

export interface EmploymentDetailsProps {
  isEmployed: boolean;
  unitDetails?: string;
  buildingName?: string;
  streetNumber: string;
  streetNameAndType: string;
  suburb: string;
  state: string;
  postcode: string;
  businessName: string;
  abn?: string;
  phone: string;
  email?: string;
  contactName: string;
  payrollId: string;
}

export interface GovernmentDetailsProps {
  countryOfBirth: string;
  townOfBirth: string;
  otherLanguages?: string;
  englishLevel?: string;
  indigenous?: string;
  highestCompletedSchoolLevel?: string;
  completedYear?: string;
  currentlyEnrolled?: boolean;
  hasCompletedQualifications?: boolean;
  employmentStatus?: string;
  employmentIndustry?: string;
  occupation?: string;
  undertakingReason?: string;
  hasDisability?: boolean;
  disabilityClaims?: string[];
  qualifications: Qualification[];
  notes?: string;
}

export interface Qualification {
  qualificationName: string;
  yearCompleted: string;
  recognition: string;
}

export interface LLNData {
  reading: string;
  writing: string;
  numeracy: string;
  oral: string;
  learning: string;
}

export interface Invitation {
  key: string;
  sentDate: string;
  url: string;
}

export interface Student {
  email: string;
  firstName: string;
  lastName: string;
}

export class AddressValidation {
  address1: string;
  address2?: string;
  suburb: string;
  state: string;
  postcode: string;
}

export interface ScanAddressResult {
  Address1?: string;
  Address2?: string;
  Address3?: string;
  Address4?: string;
  Address5?: string;
  Address6?: string;
  Suburb?: string;
  State?: string;
  PostalCode?: string;
  StatusCode?: string;
  StatusDescription?: string;
  IsStreetAddress?: string;
  IsValidAddress?: string;
  IsCorrectedAddress?: string;
  DPID?: string;
  BSP?: string;
  Barcode?: string;
  BSPName?: string;
  BSPState?: string;
  PrimaryPointFlag?: string;
  Property1?: string;
  Property2?: string;
  PropertyName?: string;
  SortDestination?: string;
  LevelNo?: string;
  LevelType?: string;
  LotNo?: string;
  ThoroughfareName?: string;
  ThoroughfareType?: string;
  ThoroughfareTypeSuffix?: string;
  UnitNo?: string;
  UnitType?: string;
  StreetNumber?: string;
  ThoroughfareNo1?: string;
  ThoroughfareNo1Suffix?: string;
  ThoroughfareNo2?: string;
  ThoroughfareNo2Suffix?: string;
  POBoxNo?: string;
  POBoxNoPrefix?: string;
  POBoxNoSuffix?: string;
  POBoxType?: string;
  NonAddressData?: string;
  CareOfData?: string;
  LastError?: string;
  LastErrorDescription?: string;
}

export interface NearMatchesResult {
  POSTmanAddressRecord?: Array<ScanAddressResult>;
}
