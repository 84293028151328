import {
  Alert,
  Button,
  Card,
  DynamicTable,
  LoadingIcon,
} from '@readcloud/component-library';
import { useRequest } from 'ahooks';
import { Skeleton } from 'antd';
import { FC, useReducer, useState } from 'react';
import { useParams } from 'react-router';
import { UncontrolledTooltip } from 'reactstrap';
import {
  bulkImport,
  createEnrolment,
  deleteInvite,
  getCourse,
  LLNData,
  resendInvite,
  Student,
  updateLLN,
} from '../../api';
import { AddStudent, BulkImport, EnrolmentActions } from './components';
import { enrolmentActions, enrolmentReducer, enrolmentsState } from './state';
// TODO: This shouldn't be here
import 'regenerator-runtime/runtime';

export const Course: FC = () => {
  const { id } = useParams<Record<string, string>>();
  const [enrolmentDisabled, setEnrolmentDisabled] = useState(false);

  const [{ enrolments }, dispatch] = useReducer(
    enrolmentReducer,
    enrolmentsState
  );
  const { data, loading, error, refresh } = useRequest(() => getCourse(id), {
    onSuccess: (result) => {
      if (result.extConfig?.program?.enrolmentCloseDate) {
        const closed = new Date(
          Date.parse(result.extConfig.program.enrolmentCloseDate)
        );
        const disabled = new Date(Date.now()).getTime() > closed.getTime();

        setEnrolmentDisabled(disabled);
      }
      dispatch(enrolmentActions.setEnrolments(result.enrolments));
    },
  });

  const onBulkImport = async (values: Student[]) => {
    const items = await bulkImport(id, values);
    dispatch(enrolmentActions.addEnrolments(items));
    //show toast
  };

  const onAddStudent = async (value: Student) => {
    const item = await createEnrolment(id, value);
    dispatch(enrolmentActions.addEnrolment(item));
    //show toast
  };

  const onDelete = async (cloudId: string, enrolmentId: string) => {
    await deleteInvite(cloudId, enrolmentId);
    dispatch(enrolmentActions.deleteEnrolment(enrolmentId));
    //show toast
  };

  const onResend = async (enrolmentId: string) => {
    await resendInvite(enrolmentId);
    await refresh();
  };

  const onUpdateLLN = async (enrolmentId: string, values: LLNData) => {
    const item = await updateLLN(enrolmentId, values);
    dispatch(enrolmentActions.updateEnrolmentLLN(item));
    //show toast
  };

  const columns = [
    {
      Header: (
        <a className="sort" data-sort="firstName">
          First Name
        </a>
      ),
      accessor: 'firstName',
      filter: 'text',
    },
    {
      Header: (
        <a className="sort" data-sort="lastName">
          Last Name
        </a>
      ),
      accessor: 'lastName',
      filter: 'text',
    },
    {
      Header: (
        <a className="sort" data-sort="email">
          Email
        </a>
      ),
      accessor: 'email',
      filter: 'text',
    },
    {
      Header: 'Status',
      accessor: 'completed',
      disableFilters: true,
      Cell: (cellProps) => {
        {
          console.log(cellProps);
        }
        if (cellProps.row.original.completed) {
          return 'Completed';
        } else if (cellProps.row.original.user) {
          return 'In Progress';
        } else if (cellProps.row.original.invitation) {
          return 'Sent';
        } else {
          return 'Unsent';
        }
      },
    },
    {
      Header: 'Actions',
      accessor: '_id',
      disableFilters: true,
      Cell: (cellProps) =>
        !cellProps.row.original.completed && (
          <EnrolmentActions
            key={cellProps.row.original._id}
            enrolment={cellProps.row.original}
            onDelete={onDelete}
            onResend={onResend}
            onUpdateLLN={onUpdateLLN}
          />
        ),
    },
  ] as any[]; // bad typings for react-table

  if (error) {
    return (
      <div className="container">
        <Alert dismissable={false} variant="danger">
          Sorry we were unable to get your course information!
        </Alert>
      </div>
    );
  }

  return (
    <div className="container">
      {loading ? (
        <Skeleton
          title={{
            style: {
              height: '28px',
              marginBottom: '1.125rem',
              marginTop: 0,
            },
          }}
          paragraph={false}
          active
        />
      ) : (
        <>
          <div className="d-flex">
            <h1 className="flex-fill">{data.name}</h1>
            <div>
              {enrolmentDisabled && (
                <span className="badge bg-warning mr-3 px-3 py-2">
                  Enrolments are closed
                </span>
              )}
              <BulkImport
                onSubmit={onBulkImport}
                disabled={enrolmentDisabled}
              />
              <AddStudent
                onSubmit={onAddStudent}
                disabled={enrolmentDisabled}
              />
              <Button
                size="sm"
                title="Refresh Students"
                onClick={refresh}
                id="refreshStudents"
              >
                <i className="fe fe-refresh-cw" />
              </Button>
              <UncontrolledTooltip placement="bottom" target="refreshStudents">
                Refresh
              </UncontrolledTooltip>
            </div>
          </div>
          <div className="d-flex">
            <h4 className="flex-fill mb-4">
              Provided by {data.rto?.name} - RTO: {data.rto?.number}
            </h4>
          </div>
          <div className="d-flex">
            <p>
              In this section of the <strong>Enrolment Portal</strong>, you’ll
              enter your student details and send individual, personalised
              emails with links inviting them to enrol. To get started, click
              the ‘+’ icon on the top right to enter details for your first
              student. Once you’ve provided each student’s details, you’ll see
              them listed in the table below. Once you’ve added your entire
              cohort, you’ll just need to click the ‘invite’ button for each
              student to send their individual <strong>invite</strong> email
              out.{' '}
            </p>
          </div>
          <div className="d-flex mb-4">
            <i>
              Note: You can complete this process individually or add students
              in bulk (via CSV upload) using the ‘add CSV’ icon in the top
              right.
            </i>
          </div>
        </>
      )}
      <Card className="course-container">
        <LoadingIcon loading={loading} position="absolute" backdrop />
        <DynamicTable
          columns={columns}
          data={enrolments || []}
          sortable
          className="inviteTable"
          defaultPageSize={10}
          pagination
        />
      </Card>
    </div>
  );
};
