import { Alert } from '@readcloud/component-library';
import { useRequest } from 'ahooks';
import { FC } from 'react';
import { getCoursesForTeacher } from '../../../../api';
import { CourseCard, CourseLoadingGrid } from './components';

export const Courses: FC = () => {
  const { data, loading, error } = useRequest(getCoursesForTeacher);

  if (loading) {
    return <CourseLoadingGrid />;
  }

  if (error) {
    return (
      <Alert dismissable={false} variant="danger">
        Sorry we were unable to get your courses!
      </Alert>
    );
  }

  return (
    <div className="row">
      {data.map((item) => (
        <div key={item.id} className="col-xs-12 col-sm-6">
          <CourseCard {...item} />
        </div>
      ))}
    </div>
  );
};
