import { Card, CardBody, CardHeader } from '@readcloud/component-library';
import { Skeleton } from 'antd';
import { FC } from 'react';

export const CourseLoadingCard: FC = () => (
  <Card>
    <CardHeader>
      <Skeleton
        title={{
          style: {
            height: '14px',
            marginTop: '14px',
          },
        }}
        paragraph={false}
        active
      />
    </CardHeader>
    <CardBody>
      <Skeleton
        title={false}
        active
        paragraph={{ rows: 3, style: { margin: 0 } }}
      />
    </CardBody>
  </Card>
);
