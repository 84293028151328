import React from 'react';
import { InputProps } from './types';

const Input: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  props,
  ref
) => (
  <>
    <input ref={ref} {...props} />
    <i className="fe fe-calendar date-picker-icon" />
  </>
);

export const IconInput = React.forwardRef(Input);
