import { LoadingIcon } from '@readcloud/component-library';
import { useRequest } from 'ahooks';
import { FC } from 'react';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import { acceptInvitation } from '../../api';
import { CentrePage, ErrorPage } from '../../components';

export const Join: FC = () => {
  const { key } = useParams<Record<string, string>>();

  const { data, loading, error } = useRequest(() => acceptInvitation(key), {
    defaultLoading: true,
  });

  if (loading) {
    return (
      <CentrePage>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <LoadingIcon loading={true} />
          Accepting your invitation. Please wait!
        </div>
      </CentrePage>
    );
  }

  if (error) {
    return <ErrorPage>{error.message}</ErrorPage>;
  }

  return <Redirect to={`/enrol/${data._id}`} />;
};
