import { UserManagerSettings } from "oidc-client";

export const environment = {
  production: false,
  baseApiUrl: "/api",
  auth: {
    client_id: "enrol",
    redirect_uri: "https://enrol.readcloud-test.com/authentication/callback",
    response_type: "code",
    scope: "openid profile email offline_access",
    authority: "https://accounts.readcloud.com",
    silent_redirect_uri:
      "https://enrol.readcloud-test.com/authentication/silent_callback",
    automaticSilentRenew: true,
    loadUserInfo: true,
  } as UserManagerSettings &
    Required<
      Pick<
        UserManagerSettings,
        "client_id" | "redirect_uri" | "scope" | "authority"
      >
    >,
};
