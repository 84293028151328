import classnames from 'classnames';
import { useField } from 'formik';
import { FC, useMemo } from 'react';
import { BaseField } from '../Base';
import { SelectProps } from './types';

export const Select: FC<SelectProps> = ({
  name,
  options = [],
  children,
  ...rest
}) => {
  const [field, meta] = useField<string>(name);

  const cachedOptions = useMemo(
    () =>
      options.map((option, index) => (
        <option key={`${option}-${index}`}>{option}</option>
      )),
    [options]
  );

  return (
    <BaseField meta={meta}>
      <select
        {...rest}
        className={classnames('form-control', {
          'is-invalid': meta.touched && Boolean(meta.error),
        })}
        name={name}
        value={meta.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
      >
        {children}
        {cachedOptions}
      </select>
    </BaseField>
  );
};
