import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@readcloud/component-library";
import { ScanAddressResult } from "packages/enrolment-portal/src/api";
import { Field, Form, Formik } from "formik";
import { FC } from "react";
import { AddressChooserProps } from "./types";
import { validationSchema } from "./validation";

/**
 * Helper to make our address look nice for display
 * @param address
 * @returns
 */
const formatAddress = (address: ScanAddressResult) => {
  let addy = address.Address1;

  if (address.Address2) addy += " " + address.Address2;
  if (address.Address3) addy += " " + address.Address3;

  addy += ["", address.Suburb, address.State, address.PostalCode].join(" ");

  return addy;
};

export const AddressChooser: FC<AddressChooserProps> = ({
  isOpen,
  title,
  message,
  options,
  onToggle,
  onSubmit,
  error,
  loading,
}) => {
  const onCancel = () => {
    onToggle(false);
  };

  const formSubmit = (values: any) => {
    const value = options.filter((o) => o.Barcode === values.barcode);
    onSubmit(value[0]);
  };

  const toggle = () => {
    if (!loading) {
      onToggle();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <ModalTitle>{title || "Select Address"}</ModalTitle>
      </ModalHeader>
      <Formik
        onSubmit={formSubmit}
        validationSchema={validationSchema}
        initialValues={{
          barcode: "",
        }}
        validateOnBlur={false}
      >
        {({ values, touched, errors, handleBlur, handleChange }) => (
          <Form>
            <ModalBody>
              <p>
                {message ||
                  "The address you've entered doesn't appear to be quite right. Here's some that are similar. Please select one before continuing:"}
              </p>
              <div>
                {options.map((x) => (
                  <label key={x.Barcode}>
                    <Field
                      type="radio"
                      name="barcode"
                      value={x.Barcode}
                      className="mr-3"
                    />
                    {formatAddress(x)}
                  </label>
                ))}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="secondary"
                onClick={onCancel}
                disabled={loading}
                className="mr-2"
              >
                Cancel
              </Button>
              <Button submit disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                Choose
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
