import * as yup from 'yup';

export const studentValidationSchema = yup.object().shape({
  acknowledgedPolicy: yup
    .bool()
    .required(
      "You must acknowledge that you've read the privacy notice before continuing."
    )
    .oneOf(
      [true],
      "You must acknowledge that you've read the privacy notice before continuing."
    ),
  acknowledgedCorrect: yup
    .bool()
    .required(
      "You must acknowledge that you've provided accurate and correct information before continuing."
    )
    .oneOf(
      [true],
      "You must acknowledge that you've provided accurate and correct information before continuing."
    ),
  acknowledgedConsent: yup
    .bool()
    .required(
      'You must consent to collection of your information before continuing.'
    )
    .oneOf(
      [true],
      'You must consent to collection of your information before continuing.'
    ),
  notes: yup.string(),
});
