import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { Enrolment } from "../../../api";
import { EnrolmentsState } from "./types";
import { WritableDraft } from "immer/dist/internal";

export const enrolmentsState: EnrolmentsState = {
  enrolments: [],
};

const slice: Slice<
  EnrolmentsState,
  {
    setEnrolments: (
      state: WritableDraft<EnrolmentsState>,
      action: PayloadAction<Enrolment[]>
    ) => void;
    deleteEnrolment: (
      state: WritableDraft<EnrolmentsState>,
      action: PayloadAction<string>
    ) => void;
    addEnrolment: (
      state: WritableDraft<EnrolmentsState>,
      action: PayloadAction<Enrolment>
    ) => void;
    addEnrolments: (
      state: WritableDraft<EnrolmentsState>,
      action: PayloadAction<Enrolment[]>
    ) => void;
    updateEnrolmentLLN: (
      state: WritableDraft<EnrolmentsState>,
      action: PayloadAction<Enrolment>
    ) => void;
  },
  "enrolments"
> = createSlice({
  name: "enrolments",
  initialState: enrolmentsState,
  reducers: {
    setEnrolments: (state, action: PayloadAction<Enrolment[]>) => {
      state.enrolments = action.payload;
    },
    deleteEnrolment: (state, action: PayloadAction<string>) => {
      state.enrolments = state.enrolments.filter(
        (item) => item._id !== action.payload
      );
    },
    addEnrolment: (state, action: PayloadAction<Enrolment>) => {
      state.enrolments.push(action.payload);
    },
    addEnrolments: (state, action: PayloadAction<Enrolment[]>) => {
      state.enrolments.push(...action.payload);
    },
    updateEnrolmentLLN: (state, action: PayloadAction<Enrolment>) => {
      const index = state.enrolments.findIndex(
        (item) => item._id === action.payload._id
      );

      if (index > -1) {
        state.enrolments[index].llnData = action.payload.llnData;
      }
    },
  },
});

export const { actions: enrolmentActions, reducer: enrolmentReducer } = slice;
