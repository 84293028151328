import {
  Card,
  CardBody,
  CardFooter,
  CardText,
} from '@readcloud/component-library';
import { FC } from 'react';
import { Enrolment } from '../../../../../../api';

export const EnrolmentCard: FC<Enrolment> = ({
  courseName,
  _id,
  completed,
  invitation,
  rto,
}) => {
  let status = 'Unsent';

  if (completed) {
    status = 'Completed';
  } else if (invitation) {
    status = 'In Progress';
  }

  return (
    <Card to={status !== 'Completed' ? `/enrol/${_id}` : ''}>
      <CardBody>
        <CardText>{courseName}</CardText>
      </CardBody>
      <CardFooter>
        <div className="d-flex justify-content-between flex-fill">
          <img src={rto?.logo} style={{ height: 32 }} />
          <div className="d-flex text-uppercase half-letter-spacing">
            <div className="d-flex justify-content-end flex-fill py-2">
              <span
                className={`badge badge-${
                  status === 'Completed'
                    ? 'success'
                    : status === 'In Progress'
                    ? 'primary'
                    : 'secondary'
                }`}
              >
                {status}
              </span>
            </div>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};
