import { Button } from '@readcloud/component-library';
import { useBoolean, useRequest } from 'ahooks';
import { FC } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import 'regenerator-runtime/runtime';
import { ConfirmModal } from '../../../../../../components';
import { DeleteInviteProps } from './types';

export const DeleteInvite: FC<DeleteInviteProps> = ({ onDelete }) => {
  const { loading, run } = useRequest(onDelete, {
    manual: true,
  });
  const [isModalOpen, { toggle: toggleModal, setTrue, setFalse }] =
    useBoolean(false);

  const onModalConfirm = async () => {
    setFalse();
    await run();
  };

  return (
    <>
      <Button
        id="deleteEnrolment"
        title="Delete enrolment"
        size="sm"
        variant="danger"
        onClick={setTrue}
        disabled={loading}
      >
        {loading ? (
          <div className="d-flex align-items-center">
            <span className="spinner-border spinner-border-sm icon-button-spinner"></span>
          </div>
        ) : (
          <i className="fe fe-trash" />
        )}
      </Button>
      <UncontrolledTooltip placement="bottom" target="deleteEnrolment">
        Delete Enrolment
      </UncontrolledTooltip>
      <ConfirmModal
        isOpen={isModalOpen}
        onCancel={setFalse}
        onConfirm={onModalConfirm}
        onToggle={toggleModal}
        title="Delete Enrolment"
      >
        Are you sure you want to delete this enrolment?
      </ConfirmModal>
    </>
  );
};
