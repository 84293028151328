import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from '@readcloud/component-library';
import { Skeleton } from 'antd';
import { FC } from 'react';

export const EnrolmentLoadingCard: FC = () => (
  <Card>
    <CardHeader>
      <Skeleton
        title={{
          style: {
            height: '14px',
            marginTop: '14px',
          },
        }}
        paragraph={false}
        active
      />
    </CardHeader>
    <CardBody>
      <Skeleton
        title={false}
        active
        paragraph={{ rows: 3, style: { margin: 0 } }}
      />
    </CardBody>
    <CardFooter>
      <Skeleton
        title={false}
        active
        paragraph={{ rows: 1, style: { margin: 0 }, width: '100%' }}
      />
    </CardFooter>
  </Card>
);
