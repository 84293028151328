import { format, parse } from 'date-fns';
import { FC } from 'react';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { DatePicker } from '../DatePicker';
import { FormattedDatePickerProps } from './types';

export const FormattedDatePicker: FC<FormattedDatePickerProps> = (props) => {
  const formatDate = (date: Date, formatter: string, locale) =>
    format(date, formatter, {
      locale,
    });

  const parseDate = (value: string, formatter: string, locale) => {
    const parsed = parse(value, formatter, new Date(), {
      locale,
    });

    if (DateUtils.isDate(parsed)) {
      return parsed;
    }

    return undefined;
  };

  return (
    <DatePicker {...props} formatDate={formatDate} parseDate={parseDate} />
  );
};
