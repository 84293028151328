import * as yup from 'yup';

export const usiValidationSchema = yup.object().shape({
  usi: yup.string().when('address.residential.isOutsideAustralia', {
    is: (val) => !val,
    then: (schema) =>
      schema
        .matches(
          /^.([A-H]|[J-N]|[P-Z]|[a-h]|[j-n]|[p-z]|[2-9]){9}$/,
          'USI must be 10 characters long, only contain numbers between 2-9 and all letters except for O and I'
        )
        .required('This is a required field'),
  }),
});
