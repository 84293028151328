const isObject = (object: any) => {
  return Object.prototype.toString.call(object) === '[object Object]';
};

export const getKeys = (obj: Record<any, any>, prefix?: string) => {
  const keys = Object.keys(obj);
  prefix = prefix ? prefix + '.' : '';

  return keys.reduce((result, key) => {
    if (isObject(obj[key])) {
      result = result.concat(getKeys(obj[key], prefix + key));
    } else {
      result.push(prefix + key);
    }

    return result;
  }, [] as string[]);
};
