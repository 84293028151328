import * as yup from 'yup';
import { qualifcationValidationSchema } from './components/Qualifications/validation';
import {
  countryOptions,
  disabilityClaimOptions,
  employmentStatusOptions,
  highestCompletedSchoolLevelOptions,
  indigenousOptions,
  languageOptions,
  undertakingReasonOptions,
} from './constants';

export const governmentValidationSchema = yup.object().shape({
  governmentDetails: yup.object({
    countryOfBirth: yup
      .string()
      .oneOf(countryOptions)
      .required('This field is required'),
    otherLanguages: yup
      .string()
      .oneOf(languageOptions, 'This field is required'),
    indigenous: yup
      .string()
      .required('This field is required')
      .oneOf(indigenousOptions, 'This field is required'),
    highestCompletedSchoolLevel: yup
      .string()
      .oneOf(highestCompletedSchoolLevelOptions, 'This field is required'),
    currentlyEnrolled: yup.bool(),
    hasCompletedQualifications: yup.bool(),
    employmentStatus: yup
      .string()
      .oneOf(employmentStatusOptions, 'This field is required'),
    undertakingReason: yup
      .string()
      .oneOf(undertakingReasonOptions, 'This field is required'),
    hasDisability: yup.boolean(),
    disabilityClaims: yup.array(
      yup.string().oneOf(disabilityClaimOptions, 'This field is required')
    ),
    qualifications: yup.array().of(qualifcationValidationSchema),
  }),
});
