import classnames from 'classnames';
import { useField } from 'formik';
import { FC, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { BaseField } from '../Base';
import { IconInput, YearMonthPicker } from './components';
import { DatePickerProps } from './types';

export const DatePicker: FC<DatePickerProps> = ({
  name,
  showYearMonthPicker,
  validateOnChange,
  inputProps,
  showIcon,
  ...rest
}) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const toMonth = new Date(currentYear, currentMonth);
  const fromMonth = new Date(currentYear - 100, 11);
  const [month, setMonth] = useState<Date>(new Date());
  const [field, meta, helpers] = useField<Date>(name);

  const onChange = (date: Date) => {
    helpers.setValue(date, validateOnChange);
  };

  const onMonthChange = (date: Date) => {
    setMonth(date);
  };

  return (
    <BaseField meta={meta}>
      <div>
        <DayPickerInput
          {...rest}
          component={showIcon && IconInput}
          inputProps={{
            ...inputProps,
            className: classnames('form-control', {
              'is-invalid': meta.touched && Boolean(meta.error),
              'pr-5': showIcon,
            }),
            type: 'text',
            name,
            autoComplete: 'off',
          }}
          onDayChange={onChange}
          onBlur={field.onBlur}
          value={new Date(meta.value)}
          {...(showYearMonthPicker
            ? {
                dayPickerProps: {
                  month,
                  fromMonth,
                  toMonth,
                  disabledDays: {
                    after: new Date(),
                  },
                  captionElement: (props) => (
                    <YearMonthPicker
                      {...props}
                      onChange={onMonthChange}
                      fromMonth={fromMonth}
                      toMonth={toMonth}
                    />
                  ),
                },
              }
            : {})}
        />
      </div>
    </BaseField>
  );
};
