import classnames from 'classnames';
import { useField } from 'formik';
import { FC } from 'react';
import { BaseField } from '../Base';
import { InputProps } from './types';

export const Input: FC<InputProps> = ({ name, ...rest }) => {
  const [field, meta] = useField<string>(name);

  return (
    <BaseField meta={meta}>
      <input
        {...rest}
        className={classnames('form-control', {
          'is-invalid': meta.touched && Boolean(meta.error),
        })}
        name={name}
        value={meta.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
      />
    </BaseField>
  );
};
