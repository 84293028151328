import { FormGroup, Label } from "@readcloud/component-library";
import { EnrolmentFormData } from "packages/enrolment-portal/src/api";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { Checkbox, Input, Select } from "../components";
import { genders, titles } from "./contstants";
import { PersonalProps } from "./types";

export const Personal: FC<PersonalProps> = ({ schoolName }) => {
  const { values, setFieldValue } = useFormikContext<EnrolmentFormData>();
  const updateField =
    (name: string) => (event: React.FocusEvent<HTMLInputElement>) => {
      let v = event.target.value.toLowerCase();
      setFieldValue(
        name,
        v.replace(/\b(\w)/g, (s) => s.toUpperCase())
      );
    };

  return (
    <div className="step">
      <h2>Personal Information</h2>
      <FormGroup>
        <Label required>Title</Label>
        <Select name="personal.title">
          <option value="" hidden>
            Select your title
          </option>
          {titles.map((option) => (
            <option key={option}>{option}</option>
          ))}
        </Select>
      </FormGroup>
      <FormGroup>
        <Label required>First Name</Label>
        <Input
          placeholder="Enter first name"
          name="personal.firstName"
          onBlurCapture={updateField("personal.firstName")}
        />
      </FormGroup>
      <FormGroup>
        <Label>Middle / Other Names</Label>
        <Input
          placeholder="Enter middle / other names"
          name="personal.otherNames"
          onBlurCapture={updateField("personal.otherNames")}
        />
      </FormGroup>
      <FormGroup>
        <Label required>Last Name</Label>
        <Input placeholder="Enter last name" name="personal.lastName" />
      </FormGroup>
      <FormGroup>
        <Label required>Date of Birth</Label>
        <Input placeholder="dd/MM/yyyy" name="personal.dob" type="date" />
      </FormGroup>
      <FormGroup>
        <Label>Gender</Label>
        <Select name="personal.gender">
          <option value="" hidden>
            Select your gender
          </option>
          {genders.map((option) => (
            <option key={option}>{option}</option>
          ))}
        </Select>
      </FormGroup>
      <hr className="my-5" />
      <h2>School Details</h2>
      <FormGroup>
        <Label>
          You are currently enrolling into a VET qualification to be delivered
          at:{" "}
        </Label>
        <Input
          defaultValue={schoolName}
          name="personal.schoolName"
          readOnly={true}
        />
      </FormGroup>
      <FormGroup>
        <Checkbox
          name="personal.differentHomeSchool"
          label="Check this box if your regular school is different from your VET school"
        />
      </FormGroup>
      {values.personal.differentHomeSchool && (
        <FormGroup>
          <Label>
            Enter the name of the school that is your usual place of study:{" "}
          </Label>
          <Input name="personal.homeSchoolName" />
        </FormGroup>
      )}
    </div>
  );
};
