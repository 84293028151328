import { Address, AddressValidation } from './types';

export const buildAddressValidation = (address: Address) => {
  let request = {
    address1: address.streetNumber + ' ' + address.streetNameAndType,
    suburb: address.suburb,
    state: address.state,
    postcode: address.postcode,
  } as AddressValidation;

  // Prepend unit details if provided

  if (address.unitDetails) {
    request.address1 = address.unitDetails + ' ' + request.address1;
  }

  return request;
};
