import { environment } from "packages/enrolment-portal/src/environments/environment.dev";
import "regenerator-runtime/runtime";

import axios from "axios";
import {
  AddressValidation,
  Course,
  Enrolment,
  EnrolmentFormData,
  LLNData,
  NearMatchesResult,
  ScanAddressResult,
  Student,
} from "./types";

const instance = axios.create({
  baseURL: `${environment.baseApiUrl}`,
});

export const addBearerToken = (token: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const getCoursesForTeacher = async () => {
  const { data } = await instance.get<Course[]>("/enrolment/teacher/courses");

  return data;
};

export const getCourse = async (id: string) => {
  const { data } = await instance.get<Course>(
    `/enrolment/teacher/courses/${id}`
  );

  return data;
};

export const getMyEnrolments = async () => {
  const { data } = await instance.get<Enrolment[]>("/enrolment/me");

  return data;
};

export const updateLLN = async (enrolmentId: string, lln: LLNData) => {
  const { data } = await instance.post<Enrolment>(
    `/enrolment/${enrolmentId}/lln`,
    lln
  );

  return data;
};

export const deleteInvite = async (cloudId: string, enrolmentId: string) => {
  const { data } = await instance.delete<string>(
    `/enrolment/${cloudId}/${enrolmentId}`
  );

  return data;
};

export const resendInvite = async (enrolmentId: string) => {
  const { data } = await instance.put<Enrolment>(
    `/enrolment/invitation/${enrolmentId}`
  );

  return data;
};

export const acceptInvitation = async (enrolmentId: string) => {
  const { data } = await instance.put<Enrolment>(
    `/enrolment/invitation/accept/${enrolmentId}`
  );

  return data;
};

export const getEnrolment = async (enrolmentId: string) => {
  const { data } = await instance.get<Enrolment>(`/enrolment/${enrolmentId}`);

  return data;
};

export const createEnrolment = async (courseId: string, student: Student) => {
  const { data } = await instance.post<Enrolment>("/enrolment", {
    courseId,
    ...student,
  });

  return data;
};

export const completeEnrolment = async (
  enrolmentId: string,
  formData: EnrolmentFormData
) => {
  const { data } = await instance.post<Enrolment>(
    `/enrolment/${enrolmentId}`,
    formData
  );

  return data;
};

export const updateEnrolment = async (
  enrolmentId: string,
  formData: EnrolmentFormData
) => {
  const { data } = await instance.put<Enrolment>(
    `/enrolment/${enrolmentId}`,
    formData
  );

  return data;
};

export const validateAddress = async (address: AddressValidation) => {
  const { data } = await instance.post<ScanAddressResult | NearMatchesResult>(
    "/address",
    address
  );
  return data;
};

export const bulkImport = async (courseId: string, students: Student[]) => {
  const promises = students.map((student) =>
    instance.post<Enrolment>("/enrolment", {
      courseId,
      ...student,
    })
  );

  const results = await Promise.all(promises);

  return results.map((result) => result.data);
};
