import { Button } from '@readcloud/component-library';
import copy from 'copy-to-clipboard';
import { FC } from 'react';
import { LLNData } from '../../../../api';
import { DeleteInvite, ResendInvite } from './components';
import { EnrolmentActionsProps } from './types';

export const EnrolmentActions: FC<EnrolmentActionsProps> = ({
  enrolment: { invitation, _id, cloud, user, firstName, lastName },
  onDelete,
  onResend,
  onUpdateLLN,
}) => {
  const onCopy = () => {
    // show toast
    copy(invitation.url);
  };
  const onHandleDelete = () => onDelete(cloud, _id);

  const onHandleResend = () => onResend(_id);

  const onHandleUpdateLLN = (values: LLNData) => onUpdateLLN(_id, values);

  return (
    <div className="d-flex">
      {!user ? (
        <ResendInvite
          onResend={onHandleResend}
          studentName={firstName + ' ' + lastName}
        />
      ) : null}
      {invitation && (
        <Button
          className="mr-2"
          title="Copy enrolment link"
          size="sm"
          onClick={onCopy}
        >
          <i className="fe fe-link" />
        </Button>
      )}
      {!invitation && <DeleteInvite onDelete={onHandleDelete} />}
    </div>
  );
};
