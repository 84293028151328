import { useReactOidc } from "@axa-fr/react-oidc-context";
import {
  Dropdown,
  DropdownItem,
  Nav,
  NavBar,
  NavBrand,
  NavItem,
} from "@readcloud/component-library";
// @ts-expect-error
import { ReactComponent as Profile } from "packages/enrolment-portal/src/profile.svg";
import { TokenContext } from "@readcloud/casl-react/dist/src/context/token";
import { useContext } from "react";

export const AppHeader = () => {
  const { logout } = useReactOidc();
  const token = useContext(TokenContext);
  const onLogout = () => logout();

  return (
    <div className="app-header">
      <Nav fixed="top">
        <div className="nav-container">
          <div className="container-fluid justify-content-between d-flex ">
            <NavBrand className="my-2" to={"/"}>
              <img
                src="/assets/readcloudvet.png"
                style={{ maxHeight: "2.2rem" }}
              />
            </NavBrand>
            <NavBar className="flex-1">
              <NavItem>
                <Dropdown
                  variant="none"
                  label={<Profile width="40px" />}
                  className="nav-link"
                  dropAlign="right"
                >
                  <DropdownItem value="profile">
                    Email: {token?.email}
                  </DropdownItem>
                  <DropdownItem value="logout" onClick={onLogout}>
                    Sign Out
                  </DropdownItem>
                </Dropdown>
              </NavItem>
            </NavBar>
          </div>
        </div>
      </Nav>
    </div>
  );
};
