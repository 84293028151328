var content = require("!!../../../node_modules/.pnpm/@nrwl+web@10.3.0_@nrwl+workspace@10.3.0_cypress@4.12.1_eslint@6.8.0_typescript@5.3.3/node_modules/@nrwl/web/src/utils/third-party/cli-files/plugins/raw-css-loader.js!../../../node_modules/.pnpm/postcss-loader@3.0.0/node_modules/postcss-loader/src/index.js??embedded!../../../node_modules/.pnpm/sass-loader@8.0.2_sass@1.77.8_webpack@4.42.0/node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-7-3!./styles.scss");

if (typeof content === 'string') {
  content = [[module.id, content, '']];
}

var options = {}

options.insert = "head";
options.singleton = false;

var update = require("!../../../node_modules/.pnpm/style-loader@1.0.0_webpack@4.42.0/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js")(content, options);

if (content.locals) {
  module.exports = content.locals;
}
