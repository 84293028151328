import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@readcloud/component-library';
import { FC } from 'react';
import { ConfirmModalProps } from './types';

export const ConfirmModal: FC<ConfirmModalProps> = ({
  isOpen,
  onToggle,
  onCancel,
  onConfirm,
  title,
  children,
}) => {
  const toggle = () => onToggle();

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      {children && <ModalBody>{children}</ModalBody>}
      <ModalFooter>
        <Button variant="secondary" onClick={onCancel} className="mr-2">
          Cancel
        </Button>
        <Button onClick={onConfirm}>Yes</Button>
      </ModalFooter>
    </Modal>
  );
};
