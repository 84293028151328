import { UserManagerSettings } from 'oidc-client';
import { environment } from '../environments/environment';

/**
 * Get the last item from a path
 * @param thePath
 * @returns
 */
export const getLastItem = (thePath) =>
  thePath.substring(thePath.lastIndexOf('/') + 1);

/**
 * Retrieves our OIDC sttings
 * @returns
 */
export const getAuthSettings = (): UserManagerSettings &
  Required<
    Pick<
      UserManagerSettings,
      'client_id' | 'redirect_uri' | 'scope' | 'authority'
    >
  > => {
  const settings = environment.auth;
  const path = window.location.pathname;

  if (path.includes('join')) {
    const enrolId = getLastItem(path);
    return {
      ...settings,
      extraQueryParams: {
        enrol_id: enrolId,
      },
    };
  }

  return settings;
};
