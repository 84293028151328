import React, { FC } from 'react';
import { YearMonthPickerProps } from './types';

export const YearMonthPicker: FC<YearMonthPickerProps> = ({
  date,
  localeUtils,
  onChange,
  fromMonth,
  toMonth,
}) => {
  const years = [];
  const months = localeUtils.getMonths();

  for (let i = toMonth.getFullYear(); i >= fromMonth.getFullYear(); i -= 1) {
    years.push(i);
  }

  const onHandleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { year, month } = event.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <div className="DayPicker-Caption">
      <select name="month" onChange={onHandleChange} value={date.getMonth()}>
        {months.map((month, index) => (
          <option key={month} value={index}>
            {month}
          </option>
        ))}
      </select>
      <select
        className="ml-2"
        name="year"
        onChange={onHandleChange}
        value={date.getFullYear()}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};
