import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@readcloud/component-library';
import { useBoolean } from 'ahooks';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { EnrolmentFormData } from '../../../../../api';
import { Checkbox, Dropdown, Select, TextArea } from '../components';
import {
  countryOptions,
  disabilityClaimOptions,
  employmentStatusOptions,
  highestCompletedSchoolLevelOptions,
  indigenousOptions,
  languageOptions,
  previousQualificationAchievedOptions,
  undertakingReasonOptions,
} from './constants';
import { GovernmentProps } from './types';

export const Government: FC<GovernmentProps> = ({ rto }) => {
  const [
    showDisabilitySupplements,
    { toggle: toggleDisabilityModal, setTrue: disabilityModalOn },
  ] = useBoolean(false);
  const { values } = useFormikContext<EnrolmentFormData>();

  const onDisabilitySupplement = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    disabilityModalOn();
  };

  return (
    <div className="step">
      <h2>Language and cultural diversity</h2>
      <FormGroup>
        <Label required>In which country were you born in?</Label>
        <Select
          name="governmentDetails.countryOfBirth"
          options={countryOptions}
        >
          <option value="" hidden>
            Select country of birth
          </option>
        </Select>
      </FormGroup>
      <FormGroup>
        <Label>Do you speak a language other than English at home?</Label>
        <Select
          name="governmentDetails.otherLanguages"
          options={languageOptions}
        >
          <option value="" hidden>
            Select other languages
          </option>
        </Select>
        <small className="text-muted">
          If more than one language, indicate the one that is spoken most often
        </small>
      </FormGroup>
      <FormGroup>
        <Label required>
          Are you of Aboriginal or Torres Strait Islander origin?
        </Label>
        <Select name="governmentDetails.indigenous" options={indigenousOptions}>
          <option value="" hidden>
            Select heritage
          </option>
        </Select>
      </FormGroup>
      <hr className="my-5" />
      <h2>Disability</h2>
      <FormGroup>
        <Checkbox
          name="governmentDetails.hasDisability"
          label="Do you consider yourself to have a disability, impairment or long-term condition?"
        />
      </FormGroup>
      {values.governmentDetails.hasDisability && (
        <FormGroup>
          <Label>
            If you indicated the presence of a disability, impairment or
            long-term condition
          </Label>
          <Dropdown
            id="governmentDetails.disabilityClaims"
            name="governmentDetails.disabilityClaims"
            multiple
            options={disabilityClaimOptions}
            placeholder="Select disability claims"
            flip
          />
          <small
            id="governmentDetails.disabilityClaims"
            className="form-text text-muted"
          >
            You may indicate one or more areas. Please refer to the{' '}
            <a href="" onClick={onDisabilitySupplement}>
              disability supplement
            </a>{' '}
            for an explanation of the following disabilities.
          </small>
        </FormGroup>
      )}
      <FormGroup>
        <Label>
          If you have any additional support needs or learning challenges and
          would like to share further information about this, please provide
          details below. This will help {rto?.name} to consider any additional
          support requirement or educational support services needed.
        </Label>
        <TextArea name="governmentDetails.notes" />
      </FormGroup>
      <hr className="my-5" />
      <h2>Schooling</h2>
      <FormGroup>
        <Label>What is your highest completed school level?</Label>
        <Select
          name="governmentDetails.highestCompletedSchoolLevel"
          options={highestCompletedSchoolLevelOptions}
        >
          <option value="" hidden>
            Select high school completion level
          </option>
        </Select>
      </FormGroup>
      <FormGroup>
        <Checkbox
          name="governmentDetails.currentlyEnrolled"
          label="Check this box if you're still enrolled in secondary or senior secondary education"
        />
      </FormGroup>
      <FormGroup>
        <Checkbox
          name="governmentDetails.hasCompletedQualifications"
          label="Check this box if you've successfully completed any other qualifications"
        />
      </FormGroup>
      {values.governmentDetails.hasCompletedQualifications && (
        <FormGroup>
          <Label required>Other qualifications achieved.</Label>
          <Dropdown
            id="governmentDetails.previousQualifications"
            name="governmentDetails.previousQualifications"
            multiple
            options={previousQualificationAchievedOptions}
            placeholder="Select previous qualification levels achieved"
            flip
          />
          <small className="text-muted">
            You may indicate one or more qualification levels
          </small>
        </FormGroup>
      )}

      <hr className="my-5" />
      <h2>Employment</h2>
      <FormGroup>
        <Label>
          Of the following categories, which best describes your current
          employment status?
        </Label>
        <Select
          id="governmentDetails.employmentStatus"
          name="governmentDetails.employmentStatus"
          options={employmentStatusOptions}
        >
          <option value="" hidden>
            Select employment status
          </option>
        </Select>
        <small
          id="governmentDetails.employmentStatus"
          className="form-text text-muted"
        >
          For casual, seasonal, contract and shift work, use current number of
          hours worked per week to determine whether full time (35 hours or more
          per week) or part-time employed (less than 35 hours per week).
        </small>
      </FormGroup>

      <hr className="my-5" />
      <h2>Study Reason</h2>

      <FormGroup>
        <Label>
          Of the following categories, select the one which best describes your
          main reason for undertaking this course/traineeship/apprenticeship?
        </Label>
        <Select
          name="governmentDetails.undertakingReason"
          options={undertakingReasonOptions}
        >
          <option value="" hidden>
            Select undertaking reason
          </option>
        </Select>
      </FormGroup>

      <Modal isOpen={showDisabilitySupplements} toggle={toggleDisabilityModal}>
        <ModalHeader>
          <ModalTitle>Disability Supplement</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>
            The purpose of the Disability supplement is to provide additional
            information to assist with answering the disability question.
          </p>
          <p>
            If you indicated the presence of a disability, impairment or
            long-term condition, please select the area(s) in the following
            list: Disability in this context does not include short-term
            disabling health conditions such as a fractured leg, influenza, or
            corrected physical conditions such as impaired vision managed by
            wearing glasses or lenses.
          </p>
          <p>
            11 — Hearing/deaf’ Hearing impairment is used to refer to a person
            who has an acquired mild, moderate, severe or profound hearing loss
            after learning to speak, communicates orally and maximises residual
            hearing with the assistance of amplification. A person who is deaf
            has a severe or profound hearing loss from, at, or near birth and
            mainly relies upon vision to communicate, whether through lip
            reading, gestures, cued speech, finger spelling and/or sign
            language.
          </p>
          <p>
            12 — Physical’ A physical disability affects the mobility or
            dexterity of a person and may include a total or partial loss of a
            part of the body. A physical disability may have existed since birth
            or may be the result of an accident, illness, or injury suffered
            later in life; for example, amputation, arthritis, cerebral palsy,
            multiple sclerosis, muscular dystrophy, paraplegia, quadriplegia or
            post-polio syndrome.
          </p>
          <p>
            13 — Intellectual’ In general, the term ‘intellectual disability’ is
            used to refer to low general intellectual functioning and
            difficulties in adaptive behaviour, both of which conditions were
            manifested before the person reached the age of 18. It may result
            from infection before or after birth, trauma during birth, or
            illness.
          </p>
          <p>
            14 — Learning’ A general term that refers to a heterogeneous group
            of disorders manifested by significant difficulties in the
            acquisition and use of listening, speaking, reading, writing,
            reasoning, or mathematical abilities. These disorders are intrinsic
            to the individual, presumed to be due to central nervous system
            dysfunction, and may occur across the life span. Problems in self
            regulatory behaviours, social perception, and social interaction may
            exist with learning disabilities but do not by themselves constitute
            a learning disability.
          </p>
          <p>
            15 — Mental illness’ Mental illness refers to a cluster of
            psychological and physiological symptoms that cause a person
            suffering or distress and which represent a departure from a
            person’s usual pattern and level of functioning.
          </p>
          <p>
            16 — Acquired brain impairment’ Acquired brain impairment is injury
            to the brain that results in deterioration in cognitive, physical,
            emotional or independent functioning. Acquired brain impairment can
            occur as a result of trauma, hypoxia, infection, tumour, accidents,
            violence, substance abuse, degenerative neurological diseases or
            stroke. These impairments may be either temporary or permanent and
            cause partial or total disability or psychosocial maladjustment.
          </p>
          <p>
            17 — Vision’ This covers a partial loss of sight causing
            difficulties in seeing, up to and including blindness. This may be
            present from birth or acquired as a result of disease, illness or
            injury.
          </p>
          <p>
            18 — Medical condition’ Medical condition is a temporary or
            permanent condition that may be hereditary, genetically acquired or
            of unknown origin. The condition may not be obvious or readily
            identifiable, yet may be mildly or severely debilitating and result
            in fluctuating levels of wellness and sickness, and/or Disability
            supplement Disability supplement 70 periods of hospitalisation; for
            example, HIV/AIDS, cancer, chronic fatigue syndrome, Crohn’s
            disease, cystic fibrosis, asthma or diabetes.
          </p>
          <p>
            19 — Other’ A disability, impairment or long-term condition which is
            not suitably described by one or several disability types in
            combination. Autism spectrum disorders are reported under this
            category.
          </p>
        </ModalBody>
      </Modal>
    </div>
  );
};
