import { Button } from '@readcloud/component-library';
import { useBoolean } from 'ahooks';
import { FC, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import 'regenerator-runtime/runtime';
import { Student } from '../../../../api';
import { AddStudentModal } from './components';
import { AddStudentProps } from './types';

export const AddStudent: FC<AddStudentProps> = ({ onSubmit, disabled }) => {
  const [loading, { toggle }] = useBoolean(false);
  const [error, setError] = useState<Error>(null);
  const [isModalOpen, { toggle: toggleModal, setTrue, setFalse }] =
    useBoolean(false);

  const onOpen = () => {
    setError(null);
    setTrue();
  };

  const onModalSubmit = async (values: Student) => {
    try {
      toggle();
      await onSubmit(values);
      setFalse();
    } catch (err) {
      setError(err);
    } finally {
      toggle();
    }
  };

  return (
    <>
      <Button
        id="addStudent"
        className="mr-2"
        size="sm"
        title="Add Student"
        onClick={onOpen}
        disabled={disabled}
      >
        <i className="fe fe-plus" />
      </Button>
      <UncontrolledTooltip placement="bottom" target="addStudent">
        Add Student
      </UncontrolledTooltip>
      <AddStudentModal
        isOpen={isModalOpen}
        onToggle={toggleModal}
        onSubmit={onModalSubmit}
        loading={loading}
        error={error}
      />
    </>
  );
};
