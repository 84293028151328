import * as yup from 'yup';

export const contactDetailsValidationSchema = yup.object().shape({
  contact: yup.object({
    homePhone: yup.string(),
    workPhone: yup.string(),
    mobile: yup.string().required('This field is required'),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('This field is required'),
  }),
});
