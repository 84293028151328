import * as yup from 'yup';

export const validationSchema = yup.object({
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('This field is required'),
});
