import { FC } from 'react';

export const Welcome: FC<{ rtoName: string }> = ({ rtoName }) => (
  <div className="step">
    <h2>Welcome to the {rtoName} Enrolment Form</h2>
    <p>
      This webform is the first step in getting started with your VET program.
      Once submitted, the details you provide will allow us to add you into our
      RTO system and provide access to the <strong>ReadCloudVET Hub</strong> and
      the Units of Competency, Assessments, Learner Resources and support for
      your course.
    </p>
    <p>
      As you click through this webform and provide your information, please
      check to ensure there are no errors, particularly in your{' '}
      <strong>
        "Name", "Date of Birth" and "Unique Student Identifier (USI)"
      </strong>
      . It's important that this information is entered correctly as they will
      allow us to verify your details and ultimately generate your final
      certificate at the end of your studies.{' '}
    </p>
    <p>
      If you have any questions at all, please ask your teacher and they will
      help you through the process.
    </p>
  </div>
);
