import { UserManagerSettings } from "oidc-client";

export const environment = {
  production: true,
  baseApiUrl: "/api",
  auth: {
    client_id: "enrol",
    redirect_uri: `${location.origin}/authentication/callback`,
    response_type: "code",
    scope: "openid profile email offline_access",
    authority: "https://accounts.readcloud.com",
    silent_redirect_uri: `${location.origin}/authentication/silent_callback`,
    automaticSilentRenew: true,
    loadUserInfo: true,
  } as UserManagerSettings &
    Required<
      Pick<
        UserManagerSettings,
        "client_id" | "redirect_uri" | "scope" | "authority"
      >
    >,
};
