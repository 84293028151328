import { useField } from "formik";
import { Typeahead, TypeaheadModel } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { TypeaheadProps } from "react-bootstrap-typeahead";
import { BaseField } from "../Base";

export function Dropdown({
  name,
  validateOnChange,
  inputProps,
  ...rest
}: TypeaheadProps<TypeaheadModel> & {
  name: string;
  validateOnChange?: boolean;
}) {
  const [field, meta, helpers] = useField<unknown[]>(name);

  const onChange = (selected: unknown[]) => {
    helpers.setValue(selected, validateOnChange);
  };

  return (
    <BaseField meta={meta}>
      <Typeahead
        {...rest}
        isInvalid={meta.touched && Boolean(meta.error)}
        inputProps={{
          ...inputProps,
          name,
        }}
        // @ts-expect-error
        selected={meta.value}
        onChange={onChange}
        onBlur={field.onBlur}
      />
    </BaseField>
  );
}
