import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
} from '@readcloud/component-library';
import { FC } from 'react';
import { Course } from '../../../../../../api';

export const CourseCard: FC<Course> = ({ name, id, memberCount, rto }) => (
  <Card to={`/course/${id}`}>
    <CardHeader>
      <h4 className="card-header-title">{name}</h4>
    </CardHeader>
    <CardBody>
      <CardText className="text-body">
        <span>
          <strong>{memberCount || 0} </strong> students enrolled.{' '}
        </span>
      </CardText>
    </CardBody>
    <CardFooter>
      <div className="d-flex justify-content-between align-items-center">
        <img src={rto?.logo} style={{ height: 32 }} />
        <span>RTO Number: {rto?.number}</span>
      </div>
    </CardFooter>
  </Card>
);
