import { FormGroup, Label } from '@readcloud/component-library';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { EnrolmentFormData } from '../../../../../api';
import { Checkbox, Input, Select } from '../components';
import { states } from './constants';

export const Address: FC = () => {
  const { values } = useFormikContext<EnrolmentFormData>();

  return (
    <div className="step">
      <h2>Residential Address</h2>
      {false && (
        <FormGroup>
          <Label>Building / Property Name</Label>
          <Input
            name="address.residential.buildingName"
            placeholder="Enter building name"
          />
        </FormGroup>
      )}
      <FormGroup>
        <Label>Flat / Unit Details</Label>
        <Input
          name="address.residential.unitDetails"
          placeholder="Enter unit details"
        />
      </FormGroup>
      <FormGroup>
        <Label required>Street or Lot Number</Label>
        <Input
          name="address.residential.streetNumber"
          placeholder="Enter street number"
          type="number"
        />
      </FormGroup>
      <FormGroup>
        <Label required>Street Name</Label>
        <Input
          name="address.residential.streetNameAndType"
          placeholder="Enter street name and type"
        />
      </FormGroup>
      <FormGroup>
        <Label required>Suburb, Locality or Town</Label>
        <Input name="address.residential.suburb" placeholder="Enter suburb" />
      </FormGroup>
      <FormGroup>
        <Checkbox
          name="address.residential.isOutsideAustralia"
          label="I live outside Australia"
        />
      </FormGroup>
      {!values.address.residential.isOutsideAustralia && (
        <FormGroup>
          <Label required>State</Label>
          <Select
            name="address.residential.state"
            disabled={values.address.residential.isOutsideAustralia}
          >
            <option value="" hidden>
              Select your state
            </option>
            {states.map((option) => (
              <option key={option}>{option}</option>
            ))}
          </Select>
        </FormGroup>
      )}
      <FormGroup>
        <Label required>Postcode</Label>
        <Input
          name="address.residential.postcode"
          placeholder="Enter postcode"
          type="number"
        />
      </FormGroup>
      <FormGroup>
        <Checkbox
          name="address.isResidentialSameAsPostal"
          label="Postal Address same as residential address"
        />
      </FormGroup>
      {!values.address.isResidentialSameAsPostal && (
        <>
          <hr className="my-5" />
          <h2>Postal Address</h2>
          {false && (
            <FormGroup>
              <Label>Building / Property Name</Label>
              <Input
                name="address.postal.buildingName"
                placeholder="Enter building name"
              />
            </FormGroup>
          )}
          <FormGroup>
            <Label>Flat / Unit Details</Label>
            <Input
              name="address.postal.unitDetails"
              placeholder="Enter unit details"
            />
          </FormGroup>
          <FormGroup>
            <Label>Street or Lot Number</Label>
            <Input
              name="address.postal.streetNumber"
              placeholder="Enter street number"
              type="number"
            />
          </FormGroup>
          <FormGroup>
            <Label>Street Name</Label>
            <Input
              name="address.postal.streetNameAndType"
              placeholder="Enter street name and type"
            />
          </FormGroup>
          <FormGroup>
            <Label>PO Box</Label>
            <Input name="address.postal.poBox" placeholder="Enter PO box" />
          </FormGroup>

          <FormGroup>
            <Label required>Suburb, Locality or Town</Label>
            <Input name="address.postal.suburb" placeholder="Enter suburb" />
          </FormGroup>
          <FormGroup>
            <Checkbox
              name="address.postal.isOutsideAustralia"
              label="I live outside Australia"
            />
          </FormGroup>
          {!values.address.postal.isOutsideAustralia && (
            <FormGroup>
              <Label required>State</Label>
              <Select
                name="address.postal.state"
                disabled={values.address.postal.isOutsideAustralia}
              >
                <option value="" hidden>
                  Select your state
                </option>
                {states.map((option) => (
                  <option key={option}>{option}</option>
                ))}
              </Select>
            </FormGroup>
          )}
          <FormGroup>
            <Label required>Postcode</Label>
            <Input
              name="address.postal.postcode"
              placeholder="Enter postcode"
              type="number"
            />
          </FormGroup>
        </>
      )}
    </div>
  );
};
