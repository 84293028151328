import { FC } from 'react';
import { CourseLoadingCard } from '../CourseLoadingCard';

export const CourseLoadingGrid: FC = () => (
  <div className="row">
    <div className="col-xs-12 col-sm-4">
      <CourseLoadingCard />
    </div>
    <div className="col-xs-12 col-sm-4">
      <CourseLoadingCard />
    </div>
    <div className="col-xs-12 col-sm-4">
      <CourseLoadingCard />
    </div>
    <div className="col-xs-12 col-sm-4">
      <CourseLoadingCard />
    </div>
    <div className="col-xs-12 col-sm-4">
      <CourseLoadingCard />
    </div>
    <div className="col-xs-12 col-sm-4">
      <CourseLoadingCard />
    </div>
  </div>
);
