import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@readcloud/component-library';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { FC } from 'react';
import { LLNPointsModalProps } from './types';
import { validationSchema } from './validation';

export const LLNPointsModal: FC<LLNPointsModalProps> = ({
  isOpen,
  onToggle,
  onSubmit,
  error,
  loading,

  values,
}) => {
  const onCancel = () => {
    onToggle(false);
  };

  const toggle = () => {
    if (!loading) {
      onToggle();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <ModalTitle>LLN Points</ModalTitle>
      </ModalHeader>
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={{
          reading: '',
          learning: '',
          numeracy: '',
          oral: '',
          writing: '',
          ...values,
        }}
        validateOnBlur={false}
      >
        {({ values, touched, errors, handleBlur, handleChange }) => (
          <Form>
            <ModalBody>
              <FormGroup>
                <Label required>Reading</Label>
                <Input
                  invalid={touched.reading && Boolean(errors.reading)}
                  placeholder="Enter reading"
                  name="reading"
                  type="number"
                  value={values.reading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min={1}
                  max={5}
                />
                {touched.reading && Boolean(errors.reading) && (
                  <div className="text-danger">{errors.reading}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label required>Writing</Label>
                <Input
                  invalid={touched.writing && Boolean(errors.writing)}
                  placeholder="Enter writing"
                  name="writing"
                  type="number"
                  value={values.writing}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min={1}
                  max={5}
                />
                {touched.writing && Boolean(errors.writing) && (
                  <div className="text-danger">{errors.writing}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label required>Numeracy</Label>
                <Input
                  invalid={touched.numeracy && Boolean(errors.numeracy)}
                  placeholder="Enter numeracy"
                  name="numeracy"
                  type="number"
                  value={values.numeracy}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min={1}
                  max={5}
                />
                {touched.numeracy && Boolean(errors.numeracy) && (
                  <div className="text-danger">{errors.numeracy}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label required>Oral</Label>
                <Input
                  invalid={touched.oral && Boolean(errors.oral)}
                  placeholder="Enter oral"
                  name="oral"
                  type="number"
                  value={values.oral}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min={1}
                  max={5}
                />
                {touched.oral && Boolean(errors.oral) && (
                  <div className="text-danger">{errors.oral}</div>
                )}
              </FormGroup>
              <div
                className={classNames({
                  'form-group': Boolean(error),
                })}
              >
                <Label required>Learning</Label>
                <Input
                  invalid={touched.learning && Boolean(errors.learning)}
                  placeholder="Enter learning"
                  name="learning"
                  type="number"
                  value={values.learning}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min={1}
                  max={5}
                />
                {touched.learning && Boolean(errors.learning) && (
                  <div className="text-danger">{errors.learning}</div>
                )}
              </div>
              {error && (
                <div className="text-danger text-center">{error.message}</div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                variant="secondary"
                disabled={loading}
                onClick={onCancel}
                className="mr-2"
              >
                Cancel
              </Button>
              <Button submit disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                Save
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
