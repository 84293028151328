import { Alert, LoadingIcon } from '@readcloud/component-library';
import { useBoolean, useCounter, useInterval, useRequest } from 'ahooks';
import { FormikHelpers } from 'formik';
import * as _ from 'lodash';
import { FC, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Redirect, useHistory } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import {
  completeEnrolment,
  EnrolmentFormData,
  getEnrolment,
  updateEnrolment,
  validateAddress,
} from '../../api';
import { buildAddressValidation } from '../../api/helpers';
import { Address, NearMatchesResult, ScanAddressResult } from '../../api/types';
import { CentrePage, Step, Wizard } from '../../components';
import {
  Actions,
  Address as AddressStep,
  addressValidationSchema,
  ContactDetails,
  contactDetailsValidationSchema,
  Government,
  governmentValidationSchema,
  Personal,
  personalValidationSchema,
  Stepper,
  Student,
  studentValidationSchema,
  USI,
  usiValidationSchema,
} from './components';
import { AddressChooser } from './components/Steps/components/AddressChooser';
import { Welcome } from './components/Steps/Welcome';
import { initialValues } from './values';

export const Enrol: FC = () => {
  const { id } = useParams<Record<string, string>>();

  const history = useHistory();
  const [delay] = useState(1000);

  const [isSubmitting, setSubmitting] = useState(false);
  const [isCompleted, setCompleted] = useState(false);
  const [isModalOpen, { toggle: toggleModal, setTrue, setFalse }] =
    useBoolean(false);
  const isModalDone = useRef(false);
  const modalValue = useRef<ScanAddressResult | undefined>(undefined);

  const [addressOptions, setAddressOptions] = useState([]);

  const [count, { dec }] = useCounter(3, { max: 3, min: 0 });

  const [isRunning, { toggle }] = useBoolean(false);
  const [submissionError, setError] = useState<Error>(null);

  const { data, loading, error } = useRequest(() => getEnrolment(id), {
    defaultLoading: true,
  });

  /**
   * Hacky as fuck - and there's bound to be a better way to do this.
   * But in the interests of time, this will have to do.
   * This will resolve once address modal is closed.
   * @returns
   */

  const doToggle = (value?: boolean) => {
    isModalDone.current = true;
    toggleModal(value);
  };

  const waitForAddressSelection = () => {
    let counter = 0; // for some sort of sanity
    let interval;

    return new Promise<boolean>((yay, nay) => {
      interval = setInterval(() => {
        if (isModalDone.current) {
          isModalDone.current = false;
          clearInterval(interval);
          yay(true);
        } else {
          counter++;
        }
        if (counter > 250) nay();
      }, 500);
    });
  };

  /**
   * Submit / complete our form
   * @param values
   */
  const onSubmit = async (values: EnrolmentFormData) => {
    try {
      setSubmitting(true);
      await completeEnrolment(id, values);
      setCompleted(true);
      toggle();
    } catch (err) {
      setError(err);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Update our form
   * @param values
   * @param helpers
   * @param currentStep
   * @returns
   */
  const onUpdate = async (
    values: EnrolmentFormData,
    helpers: any,
    currentStep: number
  ) => {
    let result = false;
    try {
      setSubmitting(true);
      await updateEnrolment(id, values);
      result = true;
    } catch (err) {
      setError(err);
    } finally {
      setSubmitting(false);
      return result;
    }
  };

  /**
   * Function to apply an address update to the form
   * @param addy
   * @param values
   * @param helpers
   */
  const applyAddress = (
    addy: ScanAddressResult,
    value: Address,
    fieldPrefix: string,
    helpers: FormikHelpers<EnrolmentFormData>
  ) => {
    value.unitDetails = addy.UnitNo;
    value.streetNumber = addy.StreetNumber;
    value.streetNameAndType =
      addy.ThoroughfareName + ' ' + addy.ThoroughfareType;
    value.suburb = addy.Suburb;
    value.postcode = addy.PostalCode;
    value.state = addy.State;

    helpers.setFieldValue(
      `address.${fieldPrefix}.unitDetails`,
      addy.UnitNo,
      false
    );
    helpers.setFieldValue(
      `address.${fieldPrefix}.streetNumber`,
      addy.StreetNumber,
      false
    );
    helpers.setFieldValue(
      `address.${fieldPrefix}.streetNameAndType`,
      addy.ThoroughfareName + ` ` + addy.ThoroughfareType,
      false
    );
    helpers.setFieldValue(`address.${fieldPrefix}.suburb`, addy.Suburb, false);
    helpers.setFieldValue(
      `address.${fieldPrefix}.postcode`,
      addy.PostalCode,
      false
    );
    helpers.setFieldValue(`address.${fieldPrefix}.state`, addy.State, false);
  };

  /**
   * Special Update function for handling address validation
   * @param values
   * @param helpers
   * @param currentStep
   * @returns
   */
  const onAddressUpdate = async (
    values: EnrolmentFormData,
    helpers: FormikHelpers<EnrolmentFormData>,
    currentStep: number
  ) => {
    let result = false;

    try {
      setSubmitting(true);

      // Residential Address

      const validation = (await validateAddress(
        buildAddressValidation(values.address.residential)
      )) as any;

      if (validation.POSTmanAddressRecord) {
        // We have a perfect result, so update the required fields

        const addy = validation as NearMatchesResult;

        setAddressOptions(addy.POSTmanAddressRecord);
        setTrue();
        await waitForAddressSelection();

        if (modalValue.current) {
          applyAddress(
            modalValue.current,
            values.address.residential,
            'residential',
            helpers
          );
          await updateEnrolment(id, values);
          result = true;
        }
      } else {
        // We have to show a modal so the muppets can select what address they
        // Want

        const addy = validation as ScanAddressResult;

        applyAddress(addy, values.address.residential, 'residential', helpers);
        await updateEnrolment(id, values);
        result = true;
      }
    } catch (err) {
      setError(err);
    } finally {
      setSubmitting(false);
      return result;
    }
  };

  const onModalSubmit = async (values?: ScanAddressResult) => {
    try {
      modalValue.current = values;
      isModalDone.current = true;
    } catch (err) {
      setError(err);
    } finally {
      setFalse();
    }
  };

  useInterval(
    () => {
      if (count === 0) {
        toggle();
        history.push('/');
      } else {
        dec();
      }
    },
    isRunning ? delay : null
  );

  const onDismiss = () => {
    setError(null);
  };

  if (loading) {
    return (
      <CentrePage>
        <LoadingIcon loading={true} />
      </CentrePage>
    );
  }

  if (error) {
    return (
      <div className="container">
        <Alert dismissable={false} variant="danger">
          Sorry we were unable to get your enrolment information!
        </Alert>
      </div>
    );
  }

  if (data?.completed) {
    return <Redirect to="/" />;
  }

  return (
    <div className="container">
      <h1 className="flex-fill mb-2">{data.courseName}</h1>
      <hr className="mb-4" />
      {submissionError ? (
        <Alert dismissable variant="danger" onClick={onDismiss}>
          {submissionError.message}
        </Alert>
      ) : null}
      <Wizard
        initialValues={_.merge(initialValues, data.formData)}
        onSubmit={onSubmit}
        resetTouchedOnStep
        onRenderActions={
          !isCompleted ? <Actions isLoading={isSubmitting} /> : null
        }
        onRenderStepper={
          <Stepper isCompleted={isCompleted} isLoading={isSubmitting} />
        }
        validateOnBlur={false}
        formProps={{ className: 'enrol-wizard d-flex flex-wrap pb-4' }}
      >
        <Step id="welcome" onSubmit={onUpdate}>
          <Welcome rtoName={data.rto.name} />
        </Step>
        <Step
          id="personal"
          validationSchema={personalValidationSchema}
          onSubmit={onUpdate}
        >
          <Personal schoolName={data.schoolName} />
        </Step>
        <Step
          id="contact"
          validationSchema={contactDetailsValidationSchema}
          onSubmit={onUpdate}
        >
          <ContactDetails />
        </Step>
        <Step
          id="address"
          validationSchema={addressValidationSchema}
          onSubmit={onAddressUpdate}
        >
          <AddressStep />
        </Step>
        <Step
          id="government"
          validationSchema={governmentValidationSchema}
          onSubmit={onUpdate}
        >
          <Government rto={data.rto} />
        </Step>
        <Step
          id="usi"
          validationSchema={usiValidationSchema}
          onSubmit={onUpdate}
        >
          <USI />
        </Step>
        <Step id="student" validationSchema={studentValidationSchema}>
          <Student
            rto={data.rto}
            isLoading={isSubmitting}
            isCompleted={isCompleted}
            count={count}
          />
        </Step>
      </Wizard>

      <AddressChooser
        isOpen={isModalOpen}
        options={addressOptions}
        onToggle={doToggle}
        onSubmit={onModalSubmit}
        loading={loading}
        error={error}
      />
    </div>
  );
};
