import { BaseFieldProps } from './types';

export function BaseField<T>(props: BaseFieldProps<T>) {
  const { meta, children } = props;

  return (
    <>
      {children}
      {meta.touched && Boolean(meta.error) && (
        <div className="text-danger">{meta.error}</div>
      )}
    </>
  );
}
