import { FormGroup, LoadingIcon } from '@readcloud/component-library';
import { FC } from 'react';
import { Checkbox } from '../components';
import { StudentProps } from './types';

export const Student: FC<StudentProps> = ({
  rto,
  isLoading,
  isCompleted,
  count,
}) => (
  <div className="step">
    {isLoading ? (
      <div className="d-flex flex-column h-100 media justify-content-center align-items-center flex-fill">
        <LoadingIcon loading={true} />
        Submitting your enrolment. Please wait!
      </div>
    ) : isCompleted ? (
      <div className="d-flex flex-column h-100 media justify-content-center align-items-center flex-fill">
        <i className="fe fe-check" />
        <div>Thank you for your enrolment. </div>
        <div>Redirecting you back home in {count}...</div>
      </div>
    ) : (
      <>
        <h2>Privacy Notice: Terms & Conditions</h2>
        <p>
          Privacy Notice Under the Data Provision Requirements 2012, {rto?.name}{' '}
          is required to collect personal information about you and to disclose
          that personal information to the National Centre for Vocational
          Education Research Ltd (NCVER). Your personal information (including
          the personal information contained on this enrolment form and your
          training activity data) may be used or disclosed by {rto?.name} for
          statistical, regulatory and research purposes. {rto?.name} may
          disclose your personal information for these purposes to third
          parties, including:{' '}
        </p>
        <ul>
          <li>
            School – if you are a secondary student undertaking VET, including a
            school-based apprenticeship or traineeship
          </li>
          <li>
            Employer – if you are enrolled in training paid by your employer;{' '}
          </li>
          <li>
            Commonwealth and State or Territory government departments and
            authorised agencies;{' '}
          </li>
          <li>
            <ul>
              <li>NCVER;</li>
              <li>Organisations conducting student surveys; and</li>
              <li>Researchers</li>
            </ul>
          </li>
        </ul>
        <p>
          We are required by law (under the National Vocational Education and
          Training Regulator Act 2011 (Cth) (NVETR Act)) to disclose the
          personal information we collect about you to the National VET Data
          Collection kept by the National Centre for Vocational Education
          Research Ltd (NCVER). The NCVER is responsible for collecting,
          managing, analysing and communicating research and statistics about
          the Australian VET sector.
        </p>
        <p>
          NCVER will collect, hold, use and disclose your personal information
          in accordance with the Privacy Act 1988 (Cth), the VET Data Policy and
          all NCVER policies and protocols (including those published on NCVER's
          website at{' '}
          <a href="www.ncver.edu.au" target="_blank">
            www.ncver.edu.au
          </a>
          ). For more information about how NCVER will handle your personal
          information please refer to the NCVER’s Privacy Policy at{' '}
          <a href="www.ncver.edu.au/privacy" target="_blank">
            www.ncver.edu.au/privacy
          </a>
          .
        </p>
        <p>
          You may receive an NCVER student survey which may be administered by
          an NCVER employee, agent or third-party contractor. You may opt out of
          the survey at the time of being contacted.
        </p>

        <hr className="my-5" />
        <h3>Student Declaration and Consert</h3>

        <FormGroup>
          <Checkbox
            name="acknowledgedPolicy"
            label="I have read and agree to the Terms & Conditions."
            required
          />
        </FormGroup>
        <FormGroup>
          <Checkbox
            name="acknowledgedCorrect"
            label={
              'I declare that the information I have provided to ' +
              rto?.name +
              ' in my enrolment, to the best of my knowledge is true and correct. By clicking on SUBMIT (and submitting this Enrolment registration form) I acknowledge and agree that I am enrolling in the training program selected on this form.'
            }
            required
          />
        </FormGroup>
        <FormGroup>
          <Checkbox
            name="acknowledgedConsent"
            label={
              'I consent to the collection, use and disclosure of my personal information in accordance with the Privacy Notice above. I also am aware that some units require student photographs to be taken for the evidence-based assessment observations to be kept on file by ' +
              rto?.name +
              ' for Audit purposes only, they will not be released for any other purposes. '
            }
            required
          />
        </FormGroup>
        <p>If you have any concerns please contact {rto?.name}.</p>
      </>
    )}
  </div>
);
