import { FormGroup, Label } from '@readcloud/component-library';
import { FC } from 'react';
import { Input } from '../components';

export const ContactDetails: FC = () => (
  <div className="step">
    <h2>Contact Details</h2>
    <FormGroup>
      <Label required>Mobile Phone</Label>
      <Input name="contact.mobile" placeholder="Enter mobile" />
    </FormGroup>
    <FormGroup>
      <Label>Email</Label>
      <Input name="contact.email" placeholder="Enter email" />
    </FormGroup>
    <FormGroup>
      <Label>Home Phone</Label>
      <Input name="contact.homePhone" placeholder="Enter home phone" />
    </FormGroup>
    <FormGroup>
      <Label>Work Phone</Label>
      <Input name="contact.workPhone" placeholder="Enter work phone" />
    </FormGroup>
  </div>
);
