import { FormGroup, Label } from '@readcloud/component-library';
import { FC } from 'react';
import { Input } from '../components';

export const USI: FC = () => (
  <div className="step">
    <h2>Unique Student Identifier</h2>
    <p>
      From 01/01/2015 Australian Institute of Education and Training as your RTO
      can be prevented from issuing you with a nationally recognised VET
      qualification or statement of attainment when you complete your course if
      you do not have a valid Unique Student Identifier (USI). In addition, we
      are required to include your USI in the data we submit to NCVER. If you
      have not yet obtained a USI you can apply for it directly at{' '}
      <a href="https://www.usi.gov.au/students/how-do-i-create-usi">
        https://www.usi.gov.au/students/how-do-i-create-usi
      </a>{' '}
      on a computer or mobile device.
    </p>
    <FormGroup>
      <Label required>USI</Label>
      <Input placeholder="Enter USI" name="usi" id="usi" maxLength={10} />
      <small id="usi" className="form-text text-muted">
        Your USI is 10 characters long, containing numbers between 2-9, and all
        letters except for O and I
      </small>
    </FormGroup>
    <h3>Cannot remember your USI?</h3>
    <p>
      If you have a USI but cannot remember it, please visit the USI Registrar
      and click on the I have forgotten my USI option. The USI Registrar will
      provide instructions for retrieving your USI.
    </p>
    <hr className="my-5" />
    <h2> Learner Unique Identifier </h2>
    <p>
      Queensland students should provide their Learner Unique Identifier (LUI).
    </p>
    <FormGroup>
      <Label>LUI</Label>
      <Input placeholder="Enter LUI" name="lui" id="lui" maxLength={10} />
      <small id="usi" className="form-text text-muted">
        Your LUI is 10 characters long
      </small>
    </FormGroup>
  </div>
);
