import { OidcSecure } from "@axa-fr/react-oidc-context";
import {
  AuthenticationProvider,
  AuthorizationProvider,
} from "@readcloud/casl-react";
import "core-js/stable";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "regenerator-runtime";
import { App } from "./App";
import { Loading } from "./components";
import "./styles.scss";
import { getAuthSettings } from "./utils/auth";

ReactDOM.render(
  <Router>
    <AuthenticationProvider
      authenticating={Loading}
      callbackComponentOverride={Loading}
      configuration={getAuthSettings()}
    >
      <AuthorizationProvider>
        <OidcSecure>
          <App />
        </OidcSecure>
      </AuthorizationProvider>
    </AuthenticationProvider>
  </Router>,
  document.getElementById("root")
);
