import classnames from 'classnames';
import { useField } from 'formik';
import { FC } from 'react';
import { BaseField } from '../Base';
import { CheckboxProps } from './types';

export const Checkbox: FC<CheckboxProps> = ({
  name,
  label,
  required,
  ...rest
}) => {
  const [field, meta] = useField<boolean>(name);

  return (
    <BaseField meta={meta}>
      <div className="form-check">
        <input
          {...rest}
          type="checkbox"
          className={classnames('form-check-input', {
            'is-invalid': meta.touched && Boolean(meta.error),
          })}
          name={name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          id={name}
          checked={meta.value}
        />
        <label
          className={classnames('form-check-label', {
            required,
          })}
          htmlFor={name}
        >
          {label}
        </label>
      </div>
    </BaseField>
  );
};
