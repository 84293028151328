import { Steps } from 'antd';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { EnrolmentFormData } from '../../../../api';
import { useWizard } from '../../../../components';
import { StepperProps } from './types';

export const Stepper: FC<StepperProps> = ({ isCompleted, isLoading }) => {
  const { step, onSetStep, stepId } = useWizard();
  const { errors, touched, submitCount } =
    useFormikContext<EnrolmentFormData>();
  const hasErrors = errors[stepId] && touched[stepId] && submitCount > 0;
  const disabled = isCompleted || isLoading;

  const onChange = (current: number) => {
    onSetStep(current);
  };

  return (
    <div>
      <Steps
        size="small"
        className="stepper mr-4"
        current={step}
        responsive
        direction="vertical"
        progressDot
        onChange={onChange}
        {...(hasErrors ? { status: 'error' } : {})}
      >
        <Steps.Step title="Welcome" disabled={disabled} />
        <Steps.Step
          title="Personal Information"
          disabled={step < 1 || disabled}
        />
        <Steps.Step title="Contact Details" disabled={step < 2 || disabled} />
        <Steps.Step title="Address" disabled={step < 3 || disabled} />

        <Steps.Step
          title="Government Data Collection Requirements"
          disabled={step < 4 || disabled}
        />
        <Steps.Step
          title="Unique Student Identifier"
          disabled={step < 5 || disabled}
        />
        <Steps.Step
          status={
            isCompleted
              ? 'finish'
              : step === 6
              ? hasErrors
                ? 'error'
                : 'process'
              : 'wait'
          }
          title="Completion"
          disabled={step < 7 || disabled}
        />
      </Steps>
    </div>
  );
};
