import { Button } from "@readcloud/component-library";
import { useBoolean } from "ahooks";
import { useFocusError } from "packages/enrolment-portal/src/hooks";
import { getKeys } from "packages/enrolment-portal/src/utils";
import { useFormikContext } from "formik";
import { FC } from "react";
import "regenerator-runtime/runtime";
import { EnrolmentFormData } from "../../../../api";
import { ConfirmModal, useWizard } from "../../../../components";
import { ActionsProps } from "./types";

export const Actions: FC<ActionsProps> = ({ isLoading }) => {
  const [
    isConfirmSubmitOpen,
    {
      toggle: toggleConfirmModal,
      setTrue: confirmModalOn,
      setFalse: confirmModalOff,
    },
  ] = useBoolean(false);
  const { isLastStep, step, onPreviousStep } = useWizard();
  const { isSubmitting, submitForm, validateForm } =
    useFormikContext<EnrolmentFormData>();

  useFocusError();

  const onConfirm = () => {
    confirmModalOff();
    submitForm();
  };

  const onValidate = async () => {
    const errors = await validateForm();
    const keys = getKeys(errors);
    if (keys.length > 0) {
      submitForm();
    } else {
      confirmModalOn();
    }
  };

  const onClickBack = () => {
    onPreviousStep();
    onScrollToTop();
  };

  const onScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="actions d-flex justify-content-end">
        {!isLoading && step > 0 ? (
          <Button
            variant="secondary"
            disabled={isSubmitting}
            onClick={onClickBack}
            className="mr-2"
          >
            Back
          </Button>
        ) : null}
        {!isLastStep ? (
          <Button disabled={isSubmitting} submit onClick={onScrollToTop}>
            <div className="d-flex">
              {isLoading && (
                <div className="d-flex align-items-center mr-1">
                  <span className="spinner-border spinner-border-sm icon-button-spinner"></span>
                </div>
              )}
              Next
            </div>
          </Button>
        ) : !isLoading ? (
          <Button disabled={isSubmitting} onClick={onValidate}>
            Submit Enrolment
          </Button>
        ) : null}
      </div>
      <ConfirmModal
        isOpen={isConfirmSubmitOpen}
        onCancel={confirmModalOff}
        onConfirm={onConfirm}
        onToggle={toggleConfirmModal}
        title="Enrol"
      >
        Are you sure you want to enrol in this course?
      </ConfirmModal>
    </>
  );
};
