import { FC } from 'react';
import { EnrolmentLoadingCard } from '../EnrolmentLoadingCard';

export const EnrolmentLoadingGrid: FC = () => (
  <div className="row">
    <div className="col-xs-12 col-sm-4">
      <EnrolmentLoadingCard />
    </div>
    <div className="col-xs-12 col-sm-4">
      <EnrolmentLoadingCard />
    </div>
    <div className="col-xs-12 col-sm-4">
      <EnrolmentLoadingCard />
    </div>
    <div className="col-xs-12 col-sm-4">
      <EnrolmentLoadingCard />
    </div>
    <div className="col-xs-12 col-sm-4">
      <EnrolmentLoadingCard />
    </div>
    <div className="col-xs-12 col-sm-4">
      <EnrolmentLoadingCard />
    </div>
  </div>
);
