import classnames from 'classnames';
import { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { CSVDropzoneProps } from './types';

export const CSVDropzone: FC<CSVDropzoneProps> = ({
  onDropAccepted,
  isLoading,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: '.csv',
    onDropAccepted,
  });

  return (
    <div
      className={classnames('dropzone', {
        'dz-drag-hover': isDragActive,
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className="dz-default dz-message">
        {isLoading ? (
          <span className="spinner-border spinner-border-sm text-primary"></span>
        ) : (
          ' Drop CSV file or click here to upload'
        )}
      </div>
    </div>
  );
};
