import * as yup from 'yup';
import { states } from './constants';

export const residentialAddressSchema = yup.object().shape({
  unitDetails: yup.string(),
  buildingName: yup.string(),
  streetNumber: yup.number().required('This field is required'),
  streetNameAndType: yup.string().required('This field is required'),
  suburb: yup.string().required('This field is required'),
  isOutsideAustralia: yup.bool(),
  state: yup.string().when('isOutsideAustralia', {
    is: (value) => !value,
    then: (schema) =>
      schema
        .required('This field is required')
        .oneOf(states, 'This field is required'),
  }),
  postcode: yup
    .string()
    .required('This field is required')
    .matches(/[0-9]{4}/, 'Must only be 4 numbers long'),
});

export const addressValidationSchema = yup.object().shape({
  address: yup.object().shape({
    residential: residentialAddressSchema,
    postal: yup.object().when('isResidentialSameAsPostal', {
      is: false,
      then: (schema) =>
        residentialAddressSchema.shape({
          streetNumber: yup.number(),
          streetNameAndType: yup.string(),
          poBox: yup.string(),
        }),
    }),
    isResidentialSameAsPostal: yup.bool(),
  }),
});
