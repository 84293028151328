import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useBoolean } from "ahooks";
import "antd/lib/skeleton/style/index.css";
import "antd/lib/steps/style/index.css";
import { useEffect } from "react";
import { addBearerToken } from "./api";
import { AppHeader, ScrollToTop } from "./components";
import { Routes } from "./routes";
import "./styles.scss";

export const App = () => {
  const [mounted, { setTrue }] = useBoolean(false);
  const { oidcUser } = useReactOidc();

  useEffect(() => {
    if (oidcUser?.access_token) {
      addBearerToken(oidcUser?.access_token);
      setTrue();
    }
  }, [oidcUser]);

  return (
    <div id="app">
      <div className="mb-4">
        <AppHeader />
      </div>
      {mounted && (
        <ScrollToTop>
          <Routes />
        </ScrollToTop>
      )}
    </div>
  );
};
