import * as yup from 'yup';
import { educationRecognitionOptions } from './constants';

export const qualifcationValidationSchema = yup.object().shape({
  qualificationName: yup.string().when('hasCompletedQualifications', {
    is: true,
    then: (schema) => schema.required('This field is required'),
  }),

  yearCompleted: yup.string().when('hasCompletedQualifications', {
    is: true,
    then: (schema) =>
      schema
        .required('This field is required')
        .matches(/[0-9]{4}/, 'Must be 4 numbers long'),
  }),

  recognition: yup.string().when('hasCompletedQualifications', {
    is: true,
    then: (schema) =>
      schema
        .oneOf(educationRecognitionOptions)
        .required('This field is required'),
  }),
});
