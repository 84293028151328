import {
  Alert,
  Button,
  Card,
  DynamicTable,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@readcloud/component-library';
import * as Papa from 'papaparse';
import { FC, useState } from 'react';
import 'regenerator-runtime/runtime';
import { Student } from '../../../../../../api';
import { downloadFile } from '../../../../../../utils';
import { CSVDropzone } from './components';
import { BulkImportModalProps } from './types';

export const BulkImportModal: FC<BulkImportModalProps> = ({
  isOpen,
  onToggle,
  onSubmit,
  error,
  loading,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [emptyDataWarning, setEmptyDataWarning] = useState(false);
  const [students, setStudents] = useState<Student[]>([]);

  const onDropAccepted = async (files: File[]) => {
    if (files.length) {
      setEmptyDataWarning(false);
      setLoading(true);
      const results = await parseCSV(files[0]);
      setStudents(results.data);
      if (results.data.length === 0) {
        setEmptyDataWarning(true);
      }
      setLoading(false);
    }
  };

  const parseCSV = (file: File) =>
    new Promise<Papa.ParseResult<Student>>((resolve, reject) => {
      Papa.parse<Student>(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          resolve(results);
        },
        error: (error) => {
          reject(error);
        },
      });
    });

  const columns = [
    {
      Header: (
        <a className="sort" data-sort="firstName">
          First Name
        </a>
      ),
      accessor: 'firstName',
      filter: 'text',
      disableFilters: true,
    },
    {
      Header: (
        <a className="sort" data-sort="lastName">
          Last Name
        </a>
      ),
      accessor: 'lastName',
      filter: 'text',
      disableFilters: true,
    },
    {
      Header: (
        <a className="sort" data-sort="email">
          Email
        </a>
      ),
      accessor: 'email',
      filter: 'text',
      disableFilters: true,
    },
  ] as any[];

  const onBack = () => {
    setStudents([]);
  };

  const toggle = () => {
    if (!loading) {
      onToggle();
      setStudents([]);
      setEmptyDataWarning(false);
    }
  };

  const onDownloadSample = () => {
    const data = [
      {
        firstName: 'John',
        lastName: 'Smith',
        email: 'jsmith@example.com',
      },
    ];

    const csvContent = Papa.unparse(data);

    downloadFile(csvContent, 'sample.csv', 'text/csv;encoding:utf-8');
  };

  const onBulkImport = async () => {
    await onSubmit(students);
    setStudents([]);
    setEmptyDataWarning(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <ModalTitle>CSV Bulk Import</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <p className="pt-2 px-2">
          In this window, you'll be able to upload a CSV file with details for
          your entire cohort to add them into the enrolment queue. To get
          started, click the button below to download a sample CSV file. Once
          you've populated the sample with your student details,{' '}
          <strong>please check to ensure there are no errors </strong>before
          uploading. It's important that the names and email addresses are
          correct, as these details will be used to create student accounts in
          our system and to send 'enrolment invite' emails. If your CSV file is
          correct and ready to import, upload via the box below.{' '}
        </p>
        {emptyDataWarning && (
          <Alert variant="warning">Your CSV is empty!</Alert>
        )}
        {students.length > 0 ? (
          <Card className="mb-0">
            <DynamicTable sortable columns={columns} data={students} />
          </Card>
        ) : (
          <CSVDropzone onDropAccepted={onDropAccepted} isLoading={isLoading} />
        )}
        {error && (
          <div className="text-danger text-center mt-4">{error.message}</div>
        )}
      </ModalBody>
      {students.length > 0 ? (
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={onBack}
            className="mr-2"
            disabled={loading}
          >
            Back
          </Button>
          <Button onClick={onBulkImport} disabled={loading}>
            {loading && (
              <span className="spinner-border spinner-border-sm mr-2"></span>
            )}
            Import
          </Button>
        </ModalFooter>
      ) : (
        <ModalFooter>
          <Button onClick={onDownloadSample}>Download Sample</Button>
        </ModalFooter>
      )}
    </Modal>
  );
};
