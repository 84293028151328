import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import 'regenerator-runtime/runtime';
import { WizardContext } from '../../context';
import { StepProps } from '../Step/types';
import { WizardProps } from './types';

export function Wizard<T>({
  children,
  onSubmit,
  resetTouchedOnStep = false,
  onRenderActions,
  onRenderStepper,
  initialStep = 0,
  formProps,
  ...rest
}: WizardProps<T>) {
  const [step, setStep] = useState(initialStep);
  const steps = React.Children.toArray(children) as React.ReactElement<
    StepProps<T>
  >[];
  const stepNode = steps[step];
  const isLastStep = step === steps.length - 1;

  const onSetStep = (value: number) => setStep(value);

  const onNextStep = () =>
    setStep((current) => Math.min(current + 1, steps.length - 1));

  const onPreviousStep = () => setStep((current) => Math.max(current - 1, 0));

  const onHandleSubmit = async (values: T, helpers: FormikHelpers<T>) => {
    let carryOn = true;

    if (stepNode.props.onSubmit) {
      carryOn = await stepNode.props.onSubmit(values, helpers, step);
    }

    if (carryOn) {
      if (isLastStep) {
        onSubmit(values, helpers);
      } else {
        if (resetTouchedOnStep) {
          helpers.setTouched({});
        }

        onNextStep();
      }
    }
  };

  return (
    <Formik
      {...rest}
      onSubmit={onHandleSubmit}
      validationSchema={stepNode.props.validationSchema}
    >
      <Form {...formProps}>
        <WizardContext.Provider
          value={{
            isLastStep,
            onNextStep,
            onPreviousStep,
            step,
            onSetStep,
            totalSteps: steps.length,
            stepId: stepNode.props.id,
          }}
        >
          {onRenderStepper}
          {stepNode}
          {onRenderActions}
        </WizardContext.Provider>
      </Form>
    </Formik>
  );
}
