import { Alert } from '@readcloud/component-library';
import { useRequest } from 'ahooks';
import { FC } from 'react';
import { getMyEnrolments } from '../../../../api';
import { EnrolmentCard, EnrolmentLoadingGrid } from './components';

export const Enrolments: FC = () => {
  const { data, loading, error } = useRequest(getMyEnrolments);

  if (loading) {
    return <EnrolmentLoadingGrid />;
  }

  if (error) {
    return (
      <Alert dismissable={false} variant="danger">
        Sorry we were unable to get your enrolments!
      </Alert>
    );
  }

  return (
    <div className="row">
      {data.map((item) => (
        <div key={item._id} className="col-xs-12 col-sm-6">
          <EnrolmentCard {...item} />
        </div>
      ))}
    </div>
  );
};
