import * as yup from 'yup';

export const personalValidationSchema = yup.object().shape({
  personal: yup.object({
    title: yup.string().required('This field is required'),
    firstName: yup.string().required('This field is required'),
    lastName: yup.string().required('This field is required'),
    dob: yup
      .date()
      .min('1900-01-01', "That's not a valid date")
      .max(new Date(), "You can't be born in the future!")
      .required('This field is required')
      .typeError('This date is invalid'),
  }),
});
